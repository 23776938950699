import styled from 'styled-components';
import { Accordion, AccordionSummary, Typography } from '@mui/material';

import colors from '../../styles/colors';

const StyledAccordion = styled(Accordion)`
  display: flex;
  flex-direction: column;
  background-color: ${colors.base.gamma};
  border-radius: 5px;
  margin-bottom: 15px;
  box-shadow: none;
  :before {
    display: none;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  & .MuiAccordionSummary-expandIconWrapper {
    ${({ $preventrotation }) => ($preventrotation && 'transform: none;')}
  }
`;

const Title = styled(Typography)`
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  text-transform: uppercase;
  color: ${colors.shades.primary1};
`;

export {
  StyledAccordion,
  StyledAccordionSummary,
  Title,
};
