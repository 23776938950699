import { TimeUnit } from '../../../../../../utils/time';
import FormField from './formFields';

const texts = {
  title: {
    add: 'Add Rest',
    edit: 'Edit Rest',
  },
  labels: {
    rest: 'Rest:',
  },
  timeUnits: {
    [TimeUnit.SECONDS]: 'Seconds',
    [TimeUnit.MINUTES]: 'Minutes',
  },
  actions: {
    add: 'Add Rest',
    update: 'Update Rest',
  },
  validation: {
    requiredField: 'Required',
    positiveNumber: 'Only positive numbers',
    integerNumber: 'Only integer numbers',
  },
  fieldLabel: {
    [FormField.REST_TIME]: 'Rest',
  },
};

export default texts;
