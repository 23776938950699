import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  StyledTabs,
  StyledTab,
} from './styles';

const Tabs = ({
  className,
  tabsConfig,
  selectedTab,
  onSelectTab,
}) => {
  const parsedTabs = useMemo(() => {
    const a11yProps = (index) => ({
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    });

    return Object.keys(tabsConfig)
      .map((tab, index) => {
        const {
          label = '',
          icon: TabIcon,
          hidden = false,
        } = tabsConfig[tab];
        return (
          <StyledTab
            label={label}
            key={tab}
            icon={TabIcon && <TabIcon />}
            value={tab}
            $hidden={hidden}
            {...a11yProps(index)}
          />
        );
      });
  }, [tabsConfig]);

  return (
    <StyledTabs
      value={selectedTab}
      onChange={(_, value) => onSelectTab(value)}
      className={className}
      variant="scrollable"
      scrollButtons
    >
      {parsedTabs}
    </StyledTabs>
  );
};

Tabs.propTypes = {
  className: PropTypes.string,
  tabsConfig: PropTypes.object.isRequired,
  selectedTab: PropTypes.string,
  onSelectTab: PropTypes.func,
};

Tabs.defaultProps = {
  className: '',
  selectedTab: 0,
  onSelectTab: () => {},
};

export default Tabs;
