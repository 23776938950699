import styled from 'styled-components';

import LoadingPage from '../../../../../../components/LoadingPage';

const StyledLoadingPage = styled(LoadingPage)`
  height: 200px;
`;

const RestrictionSettingsContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
`;

const RestrictionTagContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  width: 80%;
`;

export {
  StyledLoadingPage,
  RestrictionSettingsContainer,
  RestrictionTagContainer,
};
