import styled from 'styled-components';

import { ReactComponent as EditIcon } from '../../../../../../../assets/icons/edit_icon.svg';
import colors from '../../../../../../../styles/colors';

const StyledEditIcon = styled(EditIcon)`
  height: 16px;
  width: 16px;
  margin-right: 5px;

  > path {
    stroke: ${colors.base.secondary2};
  }
`;

export {
  StyledEditIcon,
};
