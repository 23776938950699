import React, {
  useState,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { clientInfoTabs } from '../../CoachTools/components/ClientInfo/utils';
import QAPContext, { initialValues } from './QAPContext';

const QAPContextProvider = ({
  children,
}) => {
  const [selectedUserId, setselectedUserId] = useState(initialValues.selectedUserId);
  const [selectedTab, setselectedTab] = useState(initialValues.selectedTab);

  const openQAP = useCallback((userId, defaultTab = clientInfoTabs.NOTES) => {
    setselectedUserId(userId);
    setselectedTab(defaultTab);
  }, []);

  const closeQAP = useCallback(() => {
    setselectedUserId(null);
  }, []);

  const contextValue = useMemo(() => ({
    isOpen: !!selectedUserId,
    openQAP,
    closeQAP,
    selectedUserId,
    selectedTab,
  }), [
    selectedUserId,
    openQAP,
    closeQAP,
    selectedTab,
  ]);

  return (
    <QAPContext.Provider value={contextValue}>
      {children}
    </QAPContext.Provider>
  );
};

QAPContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default QAPContextProvider;
