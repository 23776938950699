import styled from 'styled-components';
import { AddCircleOutline as AddIcon } from '@mui/icons-material';

import { ButtonIconStyle } from '../../../components/v2/Button';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit_icon.svg';
import colors from '../../../styles/colors';

const StyledAddIcon = styled(AddIcon)`
  ${ButtonIconStyle}
  color: ${colors.base.secondary2};
`;

const StyledEditIcon = styled(EditIcon)`
  width: 16px;
  height: 16px;
  margin-right: 5px;

  > path {
    stroke: ${colors.base.secondary2};
  }
`;

export {
  StyledAddIcon,
  StyledEditIcon,
};
