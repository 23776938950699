import pages from '../../config/pages';
import ManageClientsDashboard from '../../pages/ManageClients';
import ClientInfoView from '../../components/ClientInfo';

const routes = [
  {
    pageId: pages.MANAGE_CLIENTS,
    path: ':clientId/:workoutAssignmentId?',
    component: ClientInfoView,
  },
  {
    pageId: pages.MANAGE_CLIENTS,
    path: '',
    component: ManageClientsDashboard,
  },
];

export default routes;
