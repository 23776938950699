import React, {
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from '@mui/lab/DatePicker';
import { MenuItem, Tooltip } from '@mui/material';

import { StyledTextField } from '../../../../../../../../../components/Inputs';
import InputLabel from '../../../../../../../../../components/v2/InputLabel';
import { NamedProperties } from '../../../../../../../../utils/user';
import { CheckInDayLabels } from '../../../../../../../../utils/checkIn';
import profileDetailsTexts from '../../../ProfileDetailsSection/texts';
import {
  FieldContainer,
  StyledSelect,
} from '../../styles';

import texts from './texts.json';

const EMPTY_VALUE = '';
const ZERO_VALUE = 0;

const ClientDetailsEditField = ({
  profileFormData,
  setProfileFormData,
  errors,
}) => {
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const renderCheckInDayOptions = useMemo(() => (
    Object.entries(CheckInDayLabels).map(([key, value]) => (
      <MenuItem key={key} value={key}>{value}</MenuItem>
    ))
  ), []);

  const handleProfileInfoChange = (field, value) => {
    setProfileFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <>
      <FieldContainer>
        <InputLabel>{profileDetailsTexts.firstName}</InputLabel>
        <StyledTextField
          value={profileFormData.firstName}
          onChange={(e) => handleProfileInfoChange(NamedProperties.FIRST_NAME, e.target.value)}
          error={!!errors.firstName}
          helperText={errors.firstName}
        />
      </FieldContainer>
      <FieldContainer>
        <InputLabel>{profileDetailsTexts.lastName}</InputLabel>
        <StyledTextField
          value={profileFormData.lastName}
          onChange={(e) => handleProfileInfoChange(NamedProperties.LAST_NAME, e.target.value)}
          error={!!errors.lastName}
          helperText={errors.lastName}
        />
      </FieldContainer>
      <FieldContainer>
        <InputLabel>{profileDetailsTexts.email}</InputLabel>
        <Tooltip
          title={texts.supportTip}
          arrow
          PopperProps={{
            disablePortal: true,
          }}
        >
          <StyledTextField
            value={profileFormData.email}
            inputProps={{ readOnly: true }}
          />
        </Tooltip>
      </FieldContainer>
      <FieldContainer>
        <InputLabel>{profileDetailsTexts.phone}</InputLabel>
        <StyledTextField
          value={profileFormData.phoneNumber}
          onChange={(e) => handleProfileInfoChange(NamedProperties.PHONE_NUMBER, e.target.value)}
          error={!!errors.phoneNumber}
          helperText={errors.phoneNumber}
        />
      </FieldContainer>
      <FieldContainer>
        <InputLabel>{profileDetailsTexts.location}</InputLabel>
        <StyledTextField
          value={profileFormData.address}
          onChange={(e) => handleProfileInfoChange(NamedProperties.ADDRESS, e.target.value)}
        />
      </FieldContainer>
      <FieldContainer>
        <InputLabel>{profileDetailsTexts.birthdate}</InputLabel>
        <DatePicker
          value={profileFormData.birthdate}
          onChange={(date) => handleProfileInfoChange(
            NamedProperties.BIRTHDATE, date ? date.toDate() : EMPTY_VALUE,
          )}
          maxDate={moment()}
          open={openDatePicker}
          onClose={() => setOpenDatePicker(false)}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              error={!!errors.birthdate}
              helperText={errors.birthdate}
              inputProps={{ ...params.inputProps, readOnly: true }}
              onClick={() => setOpenDatePicker(true)}
            />
          )}
        />
      </FieldContainer>
      <FieldContainer>
        <InputLabel>{profileDetailsTexts.checkInDay}</InputLabel>
        <StyledSelect
          value={profileFormData.checkInDay}
          onChange={(e) => handleProfileInfoChange(
            NamedProperties.CHECK_IN_DAY,
            parseInt(e.target.value, 10) || ZERO_VALUE,
          )}
        >
          {renderCheckInDayOptions}
        </StyledSelect>
      </FieldContainer>
    </>
  );
};

ClientDetailsEditField.propTypes = {
  profileFormData: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setProfileFormData: PropTypes.func.isRequired,
};

export default ClientDetailsEditField;
