import React from 'react';
import PropTypes from 'prop-types';
import { AccordionDetails } from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';

import {
  Title,
  StyledAccordion,
  StyledAccordionSummary,
} from './styles';

const Accordion = ({
  title,
  defaultExpanded,
  children,
  expandIcon,
  preventRotation,
  ...otherProps
}) => (
  <StyledAccordion
    defaultExpanded={defaultExpanded}
    {...otherProps}
  >
    <StyledAccordionSummary expandIcon={expandIcon} $preventrotation={preventRotation}>
      {typeof title === 'string' ? <Title>{title}</Title> : title}
    </StyledAccordionSummary>
    <AccordionDetails>
      {children}
    </AccordionDetails>
  </StyledAccordion>
);

Accordion.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  defaultExpanded: PropTypes.bool,
  expandIcon: PropTypes.node,
  preventRotation: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Accordion.defaultProps = {
  defaultExpanded: false,
  preventRotation: false,
  expandIcon: <ExpandMoreIcon />,
};

export default Accordion;
