import styled from 'styled-components';

import Select from '../../../../../../../components/v2/Select';
import InputLabel from '../../../../../../../components/v2/InputLabel';
import colors from '../../../../../../../styles/colors';

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  gap: 20px;
  align-items: start;
`;

const ModalContent = styled.div`
  padding: 10px 0px;
  max-height: 55vh;
  overflow-y: auto;
`;

const Container = styled.div`
  padding: 0px 40px;
  max-height: 300px;
`;

const FieldContainer = styled.div`
  margin-top: 15px;
`;

const StyledSelect = styled(Select)`
  background-color: ${colors.base.alpha};
  width: 100%;
  line-height: 8px;
`;

const StyledInputLabel = styled(InputLabel)`
  margin-top: 14px;
`;

export {
  RowContainer,
  ModalContent,
  Container,
  FieldContainer,
  StyledSelect,
  StyledInputLabel,
};
