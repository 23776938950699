import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';

import PrimaryButton from '../../../components/Button/ActionButtons/PrimaryButton';
import ClientTaggingModal from './components/ClientTaggingModal';

import {
  StyledAddIcon,
  StyledEditIcon,
} from './styles';

const ManageClientTags = ({
  selectedClientList,
  assignedTags,
  buttonLabel,
  editButton,
}) => {
  const [showTagsModal, setShowTagsModal] = useState(false);

  return (
    <>
      <PrimaryButton
        onClick={() => setShowTagsModal(true)}
        variant="info"
        size="medium"
      >
        {!editButton ? <StyledAddIcon /> : <StyledEditIcon />}
        {buttonLabel}
      </PrimaryButton>
      {showTagsModal && (
        <ClientTaggingModal
          selectedClients={selectedClientList}
          showModal={showTagsModal}
          onClose={() => setShowTagsModal(false)}
          assignedTagsList={assignedTags}
        />
      )}
    </>
  );
};

ManageClientTags.propTypes = {
  selectedClientList: PropTypes.array.isRequired,
  assignedTags: PropTypes.array,
  buttonLabel: PropTypes.string.isRequired,
  editButton: PropTypes.bool,
};

ManageClientTags.defaultProps = {
  assignedTags: [],
  editButton: false,
};

export default ManageClientTags;
