import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import useComponentMounted from '../../../../hooks/useComponentMounted';
import useStorage from '../../../../hooks/useStorage';
import LoggedInUserContext from '../../../../context/LoggedInUserContext';
import {
  CardsContainer,
  Card,
  Container,
} from '../../../../components/v2/Card';

import { themeColorMapping } from '../../../../styles/colors';
import { isPrimaryColorEnabled } from '../../../../utils/postHog';
import { StyledTextFieldContainer } from '../../../../components/Inputs';
import InputLabel from '../../../../components/v2/InputLabel';
import {
  SaveButton,
  PrimaryButton,
} from '../../../../components/Button/ActionButtons';
import AppCustomTheme from '../../../../Model/AppCustomTheme';
import config from '../../../../config';
import AppCustomization from '../../../Model/AppCustomization';
import useToast from '../../../hooks/useToast';
import LoadingOverlay from '../../../components/LoadingOverlay';
import AutoComplete from '../../../components/AutoComplete';
import ConfirmDialog from '../../../components/ConfirmDialog';
import MobileViewModal from '../../../components/ClientInfo/components/MobileView';
import {
  HelperText,
  UpdatedSettings,
  UpdatedSettingItem,
  StyledLoadingPage,
  ThemeSectionContainer,
} from '../styles';

import PopupColorPicker, { HexColorInput } from '../../../../components/PopupColorPicker';
import WebIconConfiguration from './WebIconConfiguration';
import { uploadImage } from './utils';
import texts from './texts.json';

const MobileApp = ({
  coach: {
    id: coachId,
    label: coachName,
  },
}) => {
  const isComponentMountedRef = useComponentMounted();
  const { showToast } = useToast();
  const { uploadAttachment } = useStorage();
  const { userDoc } = useContext(LoggedInUserContext);

  const [appThemesCollection, setAppThemesCollection] = useState({ docs: [] });
  const [coachAppCustomization, setCoachAppCustomization] = useState(null);
  const [isThemesReady, setIsThemesReady] = useState(false);
  const [isCustomizationDocReady, setIsCustomizationDocReady] = useState(false);
  const [coachAppTheme, setCoachAppTheme] = useState(null);
  const [coachAppPrimaryColor, setCoachAppPrimaryColor] = useState('');
  const [imageToUpload, setImageToUpload] = useState(null);
  const [coachAppIconUrl, setCoachAppIconUrl] = useState('');
  const [showErrors, setShowErrors] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showApp, setShowApp] = useState(false);

  const mobileUrl = useMemo(() => (
    `${config.mobileAppURL}/u/${coachId}`
  ), [coachId]);

  // Reset the component state when the coach is changed
  useEffect(() => {
    const resetState = () => {
      setIsCustomizationDocReady(false);
      setCoachAppCustomization(null);
      setCoachAppTheme(null);
      setCoachAppPrimaryColor('');
      setImageToUpload(null);
      setCoachAppIconUrl('');
      setShowErrors(false);
    };

    if (coachId) {
      resetState();
    }
  }, [coachId]);

  useEffect(() => {
    const init = async () => {
      const themesCol = await AppCustomTheme.getAllThemes();
      if (isComponentMountedRef.current) {
        setAppThemesCollection(themesCol);
        setIsThemesReady(true);
      }
    };
    init();
  }, [isComponentMountedRef]);

  useEffect(() => {
    const getCoachCustomization = async () => {
      setIsCustomizationDocReady(false);
      const appCustomization = await AppCustomization.getAppCustomizationByCoach(coachId);
      if (isComponentMountedRef.current) {
        setCoachAppCustomization(appCustomization);
        setIsCustomizationDocReady(true);
      }
    };
    if (coachId) {
      getCoachCustomization();
    }
  }, [
    isComponentMountedRef,
    coachId,
  ]);

  useEffect(() => {
    if (coachAppCustomization?.baseTheme) {
      const coachTheme = appThemesCollection.docs.find((theme) => theme.id === coachAppCustomization.baseTheme);
      if (coachTheme) {
        setCoachAppTheme({ id: coachTheme.id, label: coachTheme.name });
      }
    }
    setCoachAppPrimaryColor(coachAppCustomization?.primaryColor ?? '');
  }, [
    appThemesCollection.docs,
    coachAppCustomization,
  ]);

  const appThemeOptions = useMemo(() => (
    appThemesCollection.docs.map((doc) => ({ id: doc.id, label: doc.name }))
  ), [appThemesCollection.docs]);

  const isThemeChanged = !!coachAppCustomization
    && coachAppCustomization.baseTheme !== coachAppTheme?.id;
  const primaryColorChanged = !!coachAppCustomization
    && coachAppCustomization.primaryColor !== coachAppPrimaryColor;

  const errors = useMemo(() => {
    const errorTexts = [];

    if (!isThemeChanged && !imageToUpload && !primaryColorChanged) {
      errorTexts.push(texts.errors.noChange);
    } else {
      if (!coachAppTheme?.id) {
        errorTexts.push(texts.errors.themeRequired);
      }
      if (!coachAppIconUrl) {
        errorTexts.push(texts.errors.iconRequired);
      }
    }

    return errorTexts;
  }, [
    isThemeChanged,
    primaryColorChanged,
    imageToUpload,
    coachAppTheme,
    coachAppIconUrl,
  ]);

  const dialogContent = useMemo(() => ({
    appTheme: isThemeChanged
      ? format(texts.appThemeChange, { theme: coachAppTheme?.label })
      : '',
    appIcon: imageToUpload
      ? format(texts.appIconChange)
      : '',
    appPrimaryColor: primaryColorChanged
      ? format(texts.appPrimaryColorChange, { appPrimaryColor: coachAppPrimaryColor })
      : '',
  }), [
    isThemeChanged,
    primaryColorChanged,
    coachAppPrimaryColor,
    imageToUpload,
    coachAppTheme,
  ]);

  // Hide any shown errors whenever the settings are changed
  useEffect(() => {
    setShowErrors(false);
  }, [
    coachAppTheme,
    coachAppIconUrl,
  ]);

  const handleSaveClick = useCallback(() => {
    if (errors.length > 0) {
      setShowErrors(true);
      return;
    }
    setShowModal(true);
  }, [errors]);

  const handleSave = useCallback(async () => {
    try {
      setShowModal(false);
      setIsProcessing(true);
      if (imageToUpload) {
        const fileRef = await uploadImage(
          coachAppIconUrl,
          uploadAttachment,
          imageToUpload,
          coachId,
        );
        await coachAppCustomization.updateFields({
          webAppIconRef: fileRef,
          lastUpdatedByUserName: userDoc.name,
        });
        setImageToUpload(null);
      }
      if (isThemeChanged) {
        await coachAppCustomization.setBaseTheme(coachAppTheme.id, userDoc.name);
      }
      if (primaryColorChanged) {
        await coachAppCustomization.setPrimaryColor(coachAppPrimaryColor, userDoc.name);
      }
      showToast(texts.saveSuccessful);
    } catch (error) {
      showToast(format(texts.saveFailed, { error }), { error: true });
    }
    setIsProcessing(false);
  }, [
    uploadAttachment,
    coachAppCustomization,
    coachAppTheme,
    primaryColorChanged,
    coachAppPrimaryColor,
    coachAppIconUrl,
    imageToUpload,
    isThemeChanged,
    showToast,
    coachId,
    userDoc,
  ]);

  const handleThemeChange = (theme) => {
    setCoachAppTheme(theme || {});
    const themePrimaryColor = themeColorMapping?.[theme.label];
    if (themePrimaryColor) {
      // Set primary color based on the selected theme
      setCoachAppPrimaryColor(themePrimaryColor);
    }
  };

  if (!isThemesReady || !isCustomizationDocReady) {
    return <StyledLoadingPage />;
  }

  return (
    <CardsContainer $fullWidth>
      <Card>
        <Container>
          <ThemeSectionContainer>
            <StyledTextFieldContainer $withMargin>
              <InputLabel>{texts.appTheme}</InputLabel>
              <AutoComplete
                initialValue={coachAppTheme?.id}
                options={appThemeOptions}
                onChange={handleThemeChange}
              />
            </StyledTextFieldContainer>
            {isPrimaryColorEnabled() && (
              <>
                <StyledTextFieldContainer $withMargin>
                  <InputLabel>{texts.appPrimaryColor}</InputLabel>
                  <HexColorInput
                    placeholder='Hexadecimal value (eg: "1A2B3C")'
                    color={coachAppPrimaryColor}
                    onChange={setCoachAppPrimaryColor}
                  />
                </StyledTextFieldContainer>
                <PopupColorPicker color={coachAppPrimaryColor} onChange={setCoachAppPrimaryColor} />
              </>
            )}
          </ThemeSectionContainer>
          <StyledTextFieldContainer $withMargin>
            <InputLabel>{texts.webIcon}</InputLabel>
            <WebIconConfiguration
              webAppIconRef={coachAppCustomization?.webAppIconRef}
              imageUrl={coachAppIconUrl}
              setImagePreview={setCoachAppIconUrl}
              fileToUpload={imageToUpload}
              setFileToUpload={setImageToUpload}
            />
          </StyledTextFieldContainer>
          {showErrors
            && errors.map((error) => (
              <HelperText key={error} error>
                {error}
              </HelperText>
            ))}
          <SaveButton onClick={handleSaveClick} $fitToContent>
            {texts.save}
          </SaveButton>
        </Container>
        <PrimaryButton
          onClick={() => setShowApp(true)}
          variant="link"
          $fitToContent
        >
          {texts.appPreview}
        </PrimaryButton>
      </Card>
      <MobileViewModal
        showModal={showApp}
        onClose={() => setShowApp(false)}
        mobileUrl={mobileUrl}
      />
      <ConfirmDialog
        isOpen={showModal}
        onConfirm={handleSave}
        onCancel={() => setShowModal(false)}
        dialogTexts={{
          title: format(texts.confirmTitle, { coach: coachName }),
          content: (
            <UpdatedSettings>
              {
                Object.keys(dialogContent).map((key) => (
                  !!dialogContent[key] && <UpdatedSettingItem key={key}>{dialogContent[key]}</UpdatedSettingItem>
                ))
              }
            </UpdatedSettings>
          ),
        }}
      />
      <LoadingOverlay isLoading={isProcessing} />
    </CardsContainer>
  );
};

MobileApp.propTypes = {
  coach: PropTypes.object.isRequired,
};

export default compose(
  observer,
)(MobileApp);
