const dynamicSizes = {
  MEDIUM_TABLET: {
    MIN_WIDTH: 561,
    MIN_HEIGHT: 225,
    MAX_WIDTH: 576,
    MAX_HEIGHT: 400,
  },
  TABLET: {
    MIN_WIDTH: 561,
    MIN_HEIGHT: 250,
    MAX_WIDTH: 706,
    MAX_HEIGHT: 524,
  },
  DESKTOP: {
    MIN_WIDTH: 785,
    MIN_HEIGHT: 400,
    MAX_WIDTH: 1201,
    MAX_HEIGHT: 672,
  },
};

function getDeviceType() {
  const width = window.innerWidth;
  if (width <= 640) {
    return 'MEDIUM_TABLET';
  }
  if (width <= 768) {
    return 'TABLET';
  }
  return 'DESKTOP';
}

export {
  getDeviceType,
  dynamicSizes,
};
