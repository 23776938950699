import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import format from 'string-template';

import { ReactComponent as RestrictionIcon } from '../../../../../../assets/icons/v2/restriction.svg';
import {
  Card,
  HeaderContainer,
  TitleContainer,
  Title,
  Container,
} from '../../../../../../components/v2/Card';
import { DarkTag as RestrictionTag } from '../../../../../../components/Tags';
import InputLabel from '../../../../../../components/v2/InputLabel';
import { SaveButton } from '../../../../../../components/Button/ActionButtons';
import useComponentMounted from '../../../../../../hooks/useComponentMounted';
import useToast from '../../../../../hooks/useToast';
import UserNutritionProfile, {
  DietaryRestriction,
  DietaryRestrictionText,
} from '../../../../../Model/UserNutritionProfile';
import MultiSelectFilter from '../../../../MultiSelectFilter';
import ConfirmDialog from '../../../../ConfirmDialog';
import { SecondaryText } from '../styles';

import {
  StyledLoadingPage,
  RestrictionSettingsContainer,
  RestrictionTagContainer,
} from './styles';
import texts from './texts.json';

const dietaryRestrictions = Object.values(DietaryRestriction)
  .map((tag) => ({
    value: tag,
    label: DietaryRestrictionText[tag],
  }))
  .sort((a, b) => a.label.localeCompare(b.label));

const NutritionSettings = ({ clientId }) => {
  const isComponentMountedRef = useComponentMounted();
  const { showToast } = useToast();

  const [nutritionProfile, setNutritionProfile] = useState(null);
  const [restrictions, setRestrictions] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    const init = async () => {
      const nutritionProfileDoc = await UserNutritionProfile.getById(clientId);
      if (isComponentMountedRef.current) {
        setNutritionProfile(nutritionProfileDoc);
        setRestrictions(nutritionProfileDoc?.dietaryRestrictions || []);
        setIsReady(true);
      }
    };

    init();
  }, [
    isComponentMountedRef,
    clientId,
  ]);

  const tags = useMemo(() => restrictions.map((option) => (
    <RestrictionTag key={option}>
      {DietaryRestrictionText[option]}
    </RestrictionTag>
  )), [restrictions]);

  const handleSave = useCallback(async () => {
    setShowConfirmationModal(false);
    try {
      await nutritionProfile.updateFields({
        dietaryRestrictions: restrictions,
      });
      showToast(texts.success);
    } catch (error) {
      showToast(format(texts.fail, { error }), { error: true });
    }
  }, [
    showToast,
    nutritionProfile,
    restrictions,
  ]);

  if (!isReady) {
    return <StyledLoadingPage />;
  }

  return (
    <Card>
      <HeaderContainer>
        <TitleContainer>
          <Title>{texts.title}</Title>
        </TitleContainer>
      </HeaderContainer>
      {!nutritionProfile ? <SecondaryText>{texts.noProfile}</SecondaryText>
        : (
          <>
            <Container>
              <InputLabel>{texts.restrictions}</InputLabel>
              <RestrictionSettingsContainer>
                <MultiSelectFilter
                  description={texts.filterDescription}
                  initialValues={restrictions}
                  options={dietaryRestrictions}
                  onValuesSelected={setRestrictions}
                  filterIcon={<RestrictionIcon />}
                  hideAllOption
                  disableValueRender
                />
                <RestrictionTagContainer>
                  {tags.length ? tags : <SecondaryText>{texts.noTags}</SecondaryText>}
                </RestrictionTagContainer>
              </RestrictionSettingsContainer>
            </Container>
            <SaveButton
              onClick={() => setShowConfirmationModal(true)}
              $fitToContent
            >
              {texts.save}
            </SaveButton>
          </>
        )}
      <ConfirmDialog
        isOpen={showConfirmationModal}
        onConfirm={handleSave}
        onCancel={() => setShowConfirmationModal(false)}
        dialogTexts={{
          title: texts.modalTitle,
          content: texts.modalContent,
        }}
      />
    </Card>
  );
};

NutritionSettings.propTypes = {
  clientId: PropTypes.string.isRequired,
};

export default compose(
  observer,
)(NutritionSettings);
