import React, {
  useMemo,
  useState,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import { StyledTabs } from '../../components/ClientInfo/styles';
import useSessionStore from '../../../hooks/useSessionStore';
import {
  analyticTabs,
  getAnalyticTabsConfig,
} from './utils';
import { Container, Title } from './styles';
import texts from './texts.json';

const Analytics = () => {
  const [selectedTab, setSelectedTab] = useState(analyticTabs.APP_ANALYTICS);
  const { isSupport } = useSessionStore();

  const analyticTabsConfig = useMemo(() => getAnalyticTabsConfig(isSupport), [isSupport]);

  const tabContent = useMemo(() => {
    const { component: TabContent, props } = analyticTabsConfig[selectedTab];
    return (
      <TabContent
        {...props}
      />
    );
  }, [
    selectedTab, analyticTabsConfig,
  ]);

  return (
    <>
      <Container>
        <Title>{texts.analytics}</Title>
      </Container>
      <StyledTabs
        tabsConfig={analyticTabsConfig}
        selectedTab={selectedTab}
        onSelectTab={setSelectedTab}
      />
      {tabContent}
    </>
  );
};

export default compose(
  observer,
)(Analytics);
