import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ReloadIcon } from '../../../../../assets/icons/v2/reload.svg';

import {
  Container,
  ActivityItem,
  ContentContainer,
  Tag,
  ContentText,
  SubContentText,
  LoadButton,
} from './styles';
import texts from './texts.json';

const ActivityList = ({
  activities,
  canLoadMore,
  handleLoadMore,
  emptyText,
}) => {
  const groupedActivities = useMemo(() => activities.reduce((acc, activity) => {
    if (!acc[activity.tag]) {
      acc[activity.tag] = [];
    }
    acc[activity.tag].push(activity);
    return acc;
  }, {}), [activities]);

  return (
    <Container>
      {activities.length ? (
        Object.keys(groupedActivities).map((activityDate) => (
          <ActivityItem key={activityDate}>
            <Tag>{activityDate}</Tag>
            <ContentContainer>
              {groupedActivities[activityDate].map((activity) => (
                <ContentContainer key={activity.key}>
                  <ContentText>{`- ${activity.content}`}</ContentText>
                  {!!activity.subContent && (
                    <SubContentText>{activity.subContent}</SubContentText>
                  )}
                </ContentContainer>
              ))}
            </ContentContainer>
          </ActivityItem>
        ))
      ) : emptyText}
      {canLoadMore
      && (
        <LoadButton
          onClick={handleLoadMore}
          startIcon={<ReloadIcon />}
        >
          {texts.loadButton}
        </LoadButton>
      )}
    </Container>
  );
};

ActivityList.propTypes = {
  activities: PropTypes.array.isRequired,
  canLoadMore: PropTypes.bool.isRequired,
  handleLoadMore: PropTypes.func.isRequired,
  emptyText: PropTypes.string.isRequired,
};

export default ActivityList;
