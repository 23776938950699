import styled from 'styled-components';
import { Typography } from '@mui/material';

import colors from '../../../styles/colors';
import Tabs from '../../components/Tabs';
import ColoredHeader from '../../components/ColoredHeader';
import Button from '../../../components/Button';

const Title = styled(Typography)`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 30px;
`;

const NoCoachText = styled(Typography)`
  margin: 20px;
`;

const StyledHeader = styled(ColoredHeader)`
  &:after {
    height: 42px;
  }
`;

const StyledTabs = styled(Tabs)`
  flex: 1;
  border-color: ${colors.base.primary};

  & .Mui-selected {
    background-color: ${colors.base.primary};
  }
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
`;

const StyledButton = styled(Button)`
  padding: 0 10px;
  height: 32px;
  flex-shrink: 0;
  width: fit-content;
  font-size: 16px;
  line-height: 30px;
  border-radius: 5px;
`;

export {
  Title,
  NoCoachText,
  StyledHeader,
  StyledTabs,
  StyledButton,
  OptionContainer,
};
