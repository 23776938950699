import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import * as Sentry from '@sentry/browser';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Divider } from '@mui/material';
import ReactHtmlParser from 'react-html-parser';

import useSessionStore from '../../../../../../../hooks/useSessionStore';
import useComponentMounted from '../../../../../../../hooks/useComponentMounted';
import Subscription, { subscriptionStatus as activeSubscriptionStatus } from '../../../../../../../Model/Subscription';
import UserContract from '../../../../../../../Model/UserContract';
import Plan from '../../../../../../../Model/Plan';
import { StripeSubscriptionState } from '../../../../../../../pages/Onboarding/Subscription/SubscriptionMetadata';
import { formatCurrencyCents } from '../../../../../../../utils/formatters';
import { DateFormat } from '../../../../../../../utils/date';
import { getUserStatus } from '../../../../../../utils/userStatus';
import DialogRoundedModal from '../../../../../../../components/DialogRoundedModal';
import { SaveButton, CancelButton } from '../../../../../../../components/Button/ActionButtons';
import InfoItem from '../../../InfoItem';
import colors from '../../../../../../../styles/colors';
import {
  InfoContainer,
  InfoWrapper,
  StyledLoadingPage,
  StyledSubTitle,
  ActivateUserModalContent,
  StyledOnCallActions,
  StyledTitle,
  StyledAccordion,
  StyledArrowIcon,
} from '../../styles';
import { accordionId } from '../../utils';

import texts from './texts.json';

const EMPTY_CELL = '-';

const SubscriptionDetailsSection = ({
  userDoc,
  handleExpand,
  expanded,
  isQAP,
}) => {
  const {
    id: userId,
    planId,
    subscriptionStatus: userStatus,
    isActive,
  } = userDoc;

  const {
    isOnCallUser,
  } = useSessionStore();

  const [userContractDoc, setUserContractDoc] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [showActiveUserModal, setShowActiveUserModal] = useState(false);
  const [planDoc, setPlanDoc] = useState(null);
  const [isReady, setIsReady] = useState(false);

  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const init = async () => {
      const subscription = await Subscription.getCurrentSubscription(userId);
      const userContract = await UserContract.getCurrentContractByUserId(userId);

      let plan;
      if (planId) {
        plan = await Plan.getPlanById(planId);
      }

      if (isComponentMountedRef.current) {
        if (plan) {
          setPlanDoc(plan);
        }

        if (subscription) {
          setSubscriptionData(subscription?.subscriptionData);
        }

        if (userContract) {
          setUserContractDoc(userContract);
        } else {
          Sentry.captureException(new Error(texts.noContract), {
            extra: {
              userId,
            },
          });
        }
        setIsReady(true);
      }
    };

    if (!isReady) {
      init();
    }
  }, [
    planId,
    userId,
    isReady,
    isComponentMountedRef,
  ]);

  const clientStatus = useMemo(() => computed(() => {
    const { status, associatedDateString } = getUserStatus(userDoc, DateFormat.DEFAULT_DATE_FORMAT);
    let statusText = status;
    if (associatedDateString) {
      statusText += ` (${associatedDateString})`;
    }
    return statusText;
  }), [
    userDoc,
  ]).get();

  const cancelText = useMemo(() => {
    if (!subscriptionData) {
      return EMPTY_CELL;
    }
    const cancelDate = moment.unix(subscriptionData.cancel_at);
    if (cancelDate.isAfter(moment())) {
      return cancelDate.format(DateFormat.MONTH_NAME_DATE_FORMAT);
    }
    if (subscriptionData.status === StripeSubscriptionState.CANCELED) {
      return `(${cancelDate.format(DateFormat.MONTH_NAME_DATE_FORMAT)})`;
    }
    return EMPTY_CELL;
  }, [subscriptionData]);

  const priceText = useMemo(() => {
    if (userContractDoc) {
      return formatCurrencyCents(userContractDoc.totalPriceInCents, userContractDoc.currency);
    }
    if (planDoc) {
      return formatCurrencyCents(planDoc?.totalPriceInCents, planDoc?.currency);
    }
    return EMPTY_CELL;
  }, [
    userContractDoc,
    planDoc,
  ]);

  const nextBillingText = useMemo(() => {
    if (subscriptionData
      && userDoc.subscriptionStatus
      && Object.keys(activeSubscriptionStatus).includes(userDoc.subscriptionStatus)
    ) {
      return moment.unix(subscriptionData?.current_period_end).format(DateFormat.MONTH_NAME_DATE_FORMAT);
    }
    return EMPTY_CELL;
  }, [
    userDoc,
    subscriptionData,
  ]);

  const onActivateUser = useCallback(async () => {
    await userDoc.activateUser(userId);

    if (isComponentMountedRef.current) {
      setShowActiveUserModal(false);
    }
  }, [
    userDoc,
    userId,
    isComponentMountedRef,
  ]);

  if (!isReady) {
    return (
      <StyledLoadingPage />
    );
  }

  return (
    <>
      <Divider flexItem />
      <StyledAccordion
        key={texts.subscription}
        title={<StyledTitle>{texts.subscription}</StyledTitle>}
        expanded={expanded === accordionId.SUBSCRIPTION_ACCORDION || !isQAP}
        onChange={handleExpand(accordionId.SUBSCRIPTION_ACCORDION)}
        expandIcon={isQAP && <StyledArrowIcon />}
        disableGutters
      >
        <InfoContainer>
          {(!!planDoc || !!userContractDoc || !!subscriptionData) ? (
            <InfoWrapper>
              <InfoItem
                label={texts.clientStatus}
                contentColor={clientStatus === texts.active ? colors.shades.success6 : colors.shades.danger3}
              >
                {clientStatus || EMPTY_CELL}
              </InfoItem>
              <InfoItem label={texts.clientSince}>
                {subscriptionData ? moment.unix(subscriptionData?.created)
                  .format(DateFormat.MONTH_NAME_DATE_FORMAT) : EMPTY_CELL}
              </InfoItem>
              <InfoItem label={texts.contractExpires}>
                {cancelText || EMPTY_CELL}
              </InfoItem>
              <InfoItem label={texts.package}>
                {planDoc?.planTitle || planDoc?.planCode || EMPTY_CELL}
              </InfoItem>
              <InfoItem label={texts.price}>
                {priceText || EMPTY_CELL}
              </InfoItem>
              <InfoItem label={texts.billingStatus}>
                {userDoc.subscriptionStatus || EMPTY_CELL}
              </InfoItem>
              <InfoItem label={texts.nextBilling}>
                {nextBillingText || EMPTY_CELL}
              </InfoItem>
              {isOnCallUser && (!isActive || userStatus === activeSubscriptionStatus.TRIALING) && (
                <>
                  <StyledOnCallActions
                    actions={[
                      {
                        onClick: () => setShowActiveUserModal(true),
                        disabled: false,
                        label: texts.activateUser,
                      },
                    ]}
                  />
                  <DialogRoundedModal
                    open={showActiveUserModal}
                    title={texts.activateUser}
                    onClose={() => setShowActiveUserModal(false)}
                    actionButtons={(
                      <>
                        <SaveButton
                          onClick={onActivateUser}
                        >
                          {texts.buttons.activate}
                        </SaveButton>
                        <CancelButton
                          onClick={() => setShowActiveUserModal(false)}
                        >
                          {texts.buttons.cancel}
                        </CancelButton>
                      </>
                    )}
                  >
                    <ActivateUserModalContent>
                      {ReactHtmlParser(texts.activateUserDescription)}
                    </ActivateUserModalContent>
                  </DialogRoundedModal>
                </>
              )}
            </InfoWrapper>
          ) : (
            <StyledSubTitle>
              {texts.noSubscription}
            </StyledSubTitle>
          )}
        </InfoContainer>
      </StyledAccordion>
    </>
  );
};

SubscriptionDetailsSection.propTypes = {
  userDoc: PropTypes.object.isRequired,
  handleExpand: PropTypes.func.isRequired,
  expanded: PropTypes.string.isRequired,
  isQAP: PropTypes.bool.isRequired,
};

export default compose(
  observer,
)(SubscriptionDetailsSection);
