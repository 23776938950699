import moment from 'moment';

const CheckInDayLabels = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

const getCheckInDay = (day) => moment.weekdays()[(day || 0)];

export {
  CheckInDayLabels,
  getCheckInDay,
};
