import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  height: auto;
  padding: 20px;
`;

export {
  Container,
};
