import User, { UserRoleFlag } from '../../Model/User';
import UserClaims from '../../Model/UserClaims';

/**
 * Property names of user doc.
 */
const NamedProperties = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',
  ADDRESS: 'address',
  BIRTHDATE: 'birthdate',
  CHECK_IN_DAY: 'checkInDay',
};

/**
 * Remove user from the given role.
 *
 * @param {string} userId - Id of the user to remove from the role
 * @param {string} role - The role to remove
 * @return {Promise<void>}
 */
const removeUserFromRole = async (userId, role) => {
  const userDoc = await User.getById(userId);
  await userDoc.updateFields({
    [UserRoleFlag[role]]: false,
  });
  const hasOtherRoles = Object.entries(UserRoleFlag).some(([, flag]) => flag !== UserRoleFlag[role] && userDoc[flag]);

  if (!hasOtherRoles) {
    const userClaimDoc = await UserClaims.getUserClaims(userId);
    await userClaimDoc.updateFields({
      admin: false,
    });
  }
};

export {
  removeUserFromRole,
  NamedProperties,
};
