import React, {
  useState,
  useEffect,
  useContext,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import format from 'string-template';

import useStorage from '../../../hooks/useStorage';
import useComponentMounted from '../../../hooks/useComponentMounted';
import { storagePaths, pathPlaceholder } from '../../../utils/firebasePaths';
import LoadingOverlay from '../../components/LoadingOverlay';
import FormsModel, { FormState as State } from '../../Model/Form';
import ConfirmationModal from '../../../components/v2/ConfirmationModal';
import FormsTable from '../../components/FormsTable';
import useToolsNavigation from '../../hooks/useToolsNavigation';
import useToast from '../../hooks/useToast';
import LoggedInUserContext from '../../../context/LoggedInUserContext';
import FormBuilder from '../../components/FormBuilder';
import CoachSelectModal from './CoachSelectModal';

import ActionContainer from './ActionContainer';

import {
  Container,
  HeaderContainer,
  Title,
  StyledCreationButton,
} from './styles';
import texts from './texts.json';

const Forms = () => {
  const [formState, setFormState] = useState(null);
  const [formsCollection, setformsCollection] = useState({ docs: [] });
  const [selectedForm, setSelectedForm] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showArchivedForms, setShowArchivedForms] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const isComponentMountedRef = useComponentMounted();
  const { navigateToForm } = useToolsNavigation();
  const { uploadJSON, getJSONData } = useStorage();
  const { showToast } = useToast();

  const {
    userId: createdBy,
  } = useContext(LoggedInUserContext);

  const handleBackClick = () => {
    setFormState(null);
  };

  const handleNewClick = () => {
    setFormState(State.NEW);
  };

  const handleFormDuplicateClick = (formId) => {
    const formDoc = formsCollection.docs.find((doc) => doc.id === formId);
    setSelectedForm(formDoc);
    setShowModal(true);
  };

  const handleFormArchiveClick = (formId) => {
    const formDoc = formsCollection.docs.find((doc) => doc.id === formId);
    setSelectedForm(formDoc);
    setShowConfirmation(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedForm({});
  };

  const duplicateForm = async (coach) => {
    const { data, id: existingFormId } = selectedForm;
    // We need to set the new coach name and id before saving the document
    const docData = {
      coach: coach.name,
      coachId: coach.id,
      formName: data.formName,
      createdBy,
      isArchived: false,
    };

    // Fetch form data from the storage JSON file
    const existingFormStoragePath = format(storagePaths.COACH_FORM_COLLECTION, {
      [pathPlaceholder.COACH_ID]: data.coachId,
    });
    const formData = await getJSONData(`${existingFormStoragePath}/${existingFormId}`);
    const {
      fields,
      hiddenFields,
      endScreenContent,
    } = formData;

    // Create the form config object with all the combined data
    const configData = {
      ...docData,
      fields,
      hiddenFields,
      endScreenContent,
    };

    // Add the form doc to firestore
    const newFormId = await FormsModel.addForm(docData);

    // The form config will be saved in storage for public access
    const formConfigString = JSON.stringify(configData);
    const newStoragePath = format(storagePaths.COACH_FORM_COLLECTION, {
      [pathPlaceholder.COACH_ID]: coach.id,
    });
    await uploadJSON(formConfigString, newStoragePath, newFormId);

    showToast(texts.duplicationSuccesful);
    handleModalClose();
  };

  const handleConfirmArchival = async () => {
    setIsLoading(true);
    setShowConfirmation(false);
    if (selectedForm.isArchived) {
      await selectedForm.unarchive();
    } else {
      await selectedForm.archive();
    }
    if (isComponentMountedRef.current) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const collection = await FormsModel.getForms(showArchivedForms);

      if (isComponentMountedRef.current) {
        setformsCollection(collection);
        setIsLoading(false);
      }
    };

    init();
  }, [isComponentMountedRef, showArchivedForms]);

  const renderFormsTable = () => (
    <>
      <HeaderContainer>
        <Title>{texts.formsTitle}</Title>
        <StyledCreationButton onClick={handleNewClick}>
          {texts.newForm}
        </StyledCreationButton>
      </HeaderContainer>
      <FormsTable
        rows={formsCollection.docs.slice()}
        ActionsCellComponent={ActionContainer}
        extraActionsCellProps={{
          navigateToForm,
          handleFormDuplicateClick,
          setIsLoading,
          handleFormArchiveClick,
        }}
        actionHeader={texts.actionHeader}
        handleArchivedToggle={setShowArchivedForms}
        showArchivedForms={showArchivedForms}
      />
      <CoachSelectModal
        showModal={showModal}
        onClose={handleModalClose}
        onConfirm={duplicateForm}
      />
    </>
  );

  return (
    <>
      {formState && formState === State.NEW
        ? <FormBuilder onBackClick={handleBackClick} />
        : (
          <Container>
            {renderFormsTable()}
          </Container>
        )}
      <ConfirmationModal
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={() => handleConfirmArchival()}
        title={
          selectedForm.isArchived
            ? format(texts.confirmationTitle.unarchive)
            : format(texts.confirmationTitle.archive)
        }
        description={
          selectedForm.isArchived
            ? format(texts.confirmationMessage.unarchive)
            : format(texts.confirmationMessage.archive)
        }
      />
      <LoadingOverlay isLoading={isLoading} />
    </>
  );
};

export default compose(
  observer,
)(Forms);
