import React, {
  useState,
  useMemo,
  useCallback,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import moment from 'moment';
import { forEachSeries } from 'p-iteration';
import { DeleteOutline } from '@mui/icons-material';
import format from 'string-template';

import { workoutAssignmentStatuses } from '../../../../../../../Model/WorkoutAssignment';
import DialogRoundedModal from '../../../../../../../components/DialogRoundedModal';
import { SaveButton, CancelButton } from '../../../../../../../components/Button/ActionButtons';
import LoadingOverlay from '../../../../../LoadingOverlay';
import useLogger from '../../../../../../../hooks/useLogger';
import useToast from '../../../../../../hooks/useToast';
import { CoachingActivity } from '../../../../../../../utils/log';
import text from '../../texts.json';

import {
  StyledRadioButtonGroup,
} from './styles';
import texts from './texts';
import { WorkoutDeleteType } from './utils';

const ProgramDeletionModal = ({
  showModal,
  onClose,
  selectedWorkout,
  workoutAssignments,
  futureLikeAssignments,
}) => {
  const { showToast } = useToast();
  const { logCoachingActivity } = useLogger();

  const futureWorkoutsRelatedToProgram = useMemo(() => workoutAssignments.docs
    .filter((workoutAssignment) => (selectedWorkout.programId
      && workoutAssignment.programId === selectedWorkout.programId
      && moment(workoutAssignment.startDate.toDate()).isSameOrAfter(selectedWorkout.startDate.toDate())
    )), [
    selectedWorkout,
    workoutAssignments.docs,
  ]);

  const workoutDeleteOptions = useMemo(() => {
    const options = [
      {
        value: WorkoutDeleteType.WORKOUT,
        label: texts.workoutDeleteOptionsLabels[WorkoutDeleteType.WORKOUT],
      },
    ];

    if (futureLikeAssignments.length > 1) {
      options.push({
        value: WorkoutDeleteType.MULTIPLE_WORKOUTS,
        label: format(texts.workoutDeleteOptionsLabels[WorkoutDeleteType.MULTIPLE_WORKOUTS], {
          workoutName: selectedWorkout.name,
        }),
      });
    }

    if (selectedWorkout.programId) {
      options.push({
        value: WorkoutDeleteType.PROGRAM,
        label: format(texts.workoutDeleteOptionsLabels[WorkoutDeleteType.PROGRAM], {
          count: futureWorkoutsRelatedToProgram.length,
        }),
      });
    }

    return options;
  }, [
    futureLikeAssignments,
    futureWorkoutsRelatedToProgram,
    selectedWorkout,
  ]);

  const [showLoading, setShowLoading] = useState(false);
  const [selectedDeletionOption, setSelectedDeletionOption] = useState(workoutDeleteOptions[0].value);

  const onDeletionClick = useCallback(async () => {
    setShowLoading(true);
    // Delete a single workout assignment if it's not started by the user
    if (selectedDeletionOption === WorkoutDeleteType.WORKOUT) {
      if (selectedWorkout.status === workoutAssignmentStatuses.ASSIGNED) {
        await selectedWorkout.delete();
        showToast(texts.workoutDeleted);
        logCoachingActivity(CoachingActivity.DELETED_WORKOUT_ASSIGNMENT, {
          workoutAssignmentId: selectedWorkout.id,
          clientId: selectedWorkout.user,
        });
      } else {
        showToast(text.inProgressWorkout, { warning: true });
      }
    } else if (selectedDeletionOption === WorkoutDeleteType.MULTIPLE_WORKOUTS) {
      // Delete all future workouts with a similar name that is not started by the user
      let startedWorkoutAssignments = 0;
      await forEachSeries(futureLikeAssignments, async (workoutAssignment) => {
        if (workoutAssignment.status === workoutAssignmentStatuses.ASSIGNED) {
          await workoutAssignment.delete();
        } else {
          startedWorkoutAssignments += 1;
        }
      });
      logCoachingActivity(CoachingActivity.DELETED_WORKOUT_ASSIGNMENTS, {
        workoutAssignmentName: selectedWorkout.name,
        clientId: selectedWorkout.user,
      });
      if (startedWorkoutAssignments > 0) {
        showToast(format(texts.workoutsInProgress, {
          count: startedWorkoutAssignments,
          workoutName: selectedWorkout.name,
        }));
      } else {
        showToast(texts.workoutsDeleted);
      }
    } else {
      // Delete all future workouts related to the deleted assignment's program that is not started by the user
      let startedWorkoutAssignments = 0;
      await forEachSeries(futureWorkoutsRelatedToProgram, async (workoutAssignment) => {
        if (workoutAssignment.status === workoutAssignmentStatuses.ASSIGNED) {
          await workoutAssignment.delete();
        } else {
          startedWorkoutAssignments += 1;
        }
      });
      logCoachingActivity(CoachingActivity.DELETED_PROGRAM_ASSIGNMENT, {
        programId: selectedWorkout.programId,
        clientId: selectedWorkout.user,
      });
      if (startedWorkoutAssignments > 0) {
        showToast(format(texts.programWorkoutsInProgress, {
          count: startedWorkoutAssignments,
        }));
      } else {
        showToast(texts.programDeleted);
      }
    }
    setShowLoading(false);
    onClose();
  }, [
    selectedDeletionOption,
    selectedWorkout,
    showToast,
    logCoachingActivity,
    futureLikeAssignments,
    futureWorkoutsRelatedToProgram,
    onClose,
  ]);

  return (
    <DialogRoundedModal
      title={texts.title}
      actionButtons={(
        <>
          <SaveButton
            disabled={!selectedWorkout}
            icon={<DeleteOutline />}
            onClick={onDeletionClick}
          >
            {texts.delete}
          </SaveButton>
          <CancelButton onClick={onClose}>{texts.cancel}</CancelButton>
        </>
      )}
      open={showModal}
      onClose={onClose}
    >
      <StyledRadioButtonGroup
        options={workoutDeleteOptions}
        onOptionChange={(type) => setSelectedDeletionOption(type)}
        selectedOption={selectedDeletionOption}
      />
      <LoadingOverlay isLoading={showLoading} />
    </DialogRoundedModal>
  );
};

ProgramDeletionModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedWorkout: PropTypes.object.isRequired,
  workoutAssignments: PropTypes.object.isRequired,
  futureLikeAssignments: PropTypes.array,
};

ProgramDeletionModal.defaultProps = {
  futureLikeAssignments: [],
};

export default compose(
  observer,
)(ProgramDeletionModal);
