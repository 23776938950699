import styled from 'styled-components';
import {
  FormHelperText,
  MenuItem,
} from '@mui/material';

import colors from '../../../../../styles/colors';
import { ReactComponent as CircuitIcon } from '../../../../../assets/icons/v2/distribute-spacing-vertical.svg';
import FormikInput from '../../../../../components/v2/FormikInput';

const videoPreviewWidth = '220px';

const StyledCircuitIcon = styled(CircuitIcon)`
  width: 24px;
  height: 24px;
`;

const FormContainer = styled.div`
  padding: 24px 50px 10px 50px;
  display: flex;
  gap: 50px;
  .MuiInputBase-multiline {
    box-sizing: content-box;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - ${videoPreviewWidth});
`;

const VideoPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: ${videoPreviewWidth};
`;

const VideoPreview = styled.iframe`
  width: ${videoPreviewWidth};
`;

const ButtonContainer = styled.div`
  margin: 50px 10px 25px 50px;
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  .MuiOutlinedInput-root {
    min-width: 150px;
  }
  .MuiInputBase-root {
    width: 100%;
    height: 40px;
  }
`;

const SetContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
`;

const InputSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 12px;
`;

const VideoPreviewTitle = styled(FormHelperText)`
  font-size: 14px;
  text-align: center;
  color: ${colors.base.secondary2};
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 310px;
  margin-bottom: 20px;
`;

const StyledFormikInput = styled(FormikInput)`
  margin: 0;

  .MuiOutlinedInput-root {
    height: 45px;
  }
`;

const RadioGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const ExerciseChangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 24px;
  height: 85vh;
`;

const Title = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
`;

export {
  StyledCircuitIcon,
  FormContainer,
  ContentContainer,
  VideoPreviewContainer,
  VideoPreview,
  ButtonContainer,
  SelectContainer,
  InputSelectContainer,
  SetContainer,
  StyledMenuItem,
  VideoPreviewTitle,
  SubContainer,
  StyledFormikInput,
  RadioGroupContainer,
  ExerciseChangeContainer,
  Title,
};
