import styled from 'styled-components';
import {
  FormHelperText,
} from '@mui/material';

import colors from '../../../../../../../styles/colors';
import StyledTextField from '../../../../../../../components/v2/TextField';
import InputLabel from '../../../../../../../components/v2/InputLabel';

const StyledHelperText = styled(FormHelperText)`
  margin-top: 0;
  font-size: 13px;
  line-height: 14px;
  font-weight: 450;
  color: unset;
`;

const RepeatSettingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
`;

const StyledInputLabel = styled(InputLabel)`
  font-size: 14px;
  color: ${colors.shades.secondary8};
`;

const StyledInput = styled(StyledTextField)`
  width: 70px;
  margin: 0;

  .MuiInputBase-input {
    height: 10px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 60px;
`;

const SettingSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;

const StyledDurationText = styled(FormHelperText)`
  margin-top: 15px;
  font-size: 13px;
  line-height: 14px;
  font-weight: 450;
  color: unset;
`;

export {
  StyledHelperText,
  RepeatSettingContainer,
  StyledInputLabel,
  StyledInput,
  Container,
  SettingSection,
  StyledDurationText,
};
