import { BiologicalSex } from '../../../../../../../../utils/macroGoals';

const texts = {
  genderValues: {
    [BiologicalSex.MALE]: 'Male',
    [BiologicalSex.FEMALE]: 'Female',
  },
  cm: 'cm',
  ft: 'ft',
  inches: 'inches',
  number: 'number',
  unitOfWeight: 'Unit of Weight',
  unitOfHeight: 'Unit of Height',
};

export default texts;
