import styled from 'styled-components';
import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import Popper from '@mui/material/Popper';
import { ResizableBox } from 'react-resizable';

import colors from '../../../styles/colors';

const CloseButton = styled(IconButton)`
  position: absolute;
  top: ${({ $fullScreen }) => ($fullScreen ? '30px' : 0)};
  right: ${({ $fullScreen }) => ($fullScreen ? '30px' : 0)};
  background: ${colors.shades.beta2};
  transform: translate(100%, -100%);
  z-index: 100;

  &:hover{
    background: ${colors.shades.beta1};
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  color: ${colors.base.alpha};
`;

const Handle = styled('div')`
  width: 10px;
  height: 10px;
  background-color: lightgray;
  border: 1px solid gray;
  position: fixed;
  bottom: -5px;
  right: -5px;
  cursor: se-resize;
`;

const StyledPopper = styled(Popper)`
  border: 1px solid ${colors.shades.gamma3};
  margin: 5% 6%;

  @media screen and (max-width: 768px) {
    margin: 2% 4%;
  }

  @media screen and (max-width: 640px) {
    margin: 12% 5%;
  }
`;

const StyledResizableBox = styled(ResizableBox)`
  overflow-y: scroll;
  background: ${colors.shades.secondary5};

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.shades.gamma3};
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background: ${colors.shades.gamma4};
  }
`;

export {
  CloseButton,
  StyledCloseIcon,
  Handle,
  StyledPopper,
  StyledResizableBox,
};
