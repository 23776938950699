import React, {
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { isInsideSalesAdmin } from '../../../utils/postHog';
import CoachSelect from '../../components/Leads/components/CoachSelect';
import CoachesListContext from '../../context/CoachesListContext';
import ConfirmDialog from '../../components/ConfirmDialog';

import {
  StyledHeader,
  Title,
  NoCoachText,
  StyledTabs,
  StyledButton,
  OptionContainer,
} from './styles';
import texts from './texts.json';

const CoachConfigurations = ({
  defaultTab,
  tabsConfig,
  role,
  isISConfigView,
}) => {
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const [selectedCoach, setSelectedCoach] = useState({});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const {
    coachesCollection,
  } = useContext(CoachesListContext);

  const tabContent = useMemo(() => {
    const { component: TabContent, props } = tabsConfig[selectedTab];

    if (!selectedCoach.id) {
      return (
        <NoCoachText>{texts.noCoachSelected}</NoCoachText>
      );
    }

    return (
      <TabContent
        coach={selectedCoach}
        {...props}
      />
    );
  }, [
    tabsConfig,
    selectedTab,
    selectedCoach,
  ]);

  const selectedCoachData = useMemo(() => (
    coachesCollection.docs.find(({ id }) => id === selectedCoach.id)
  ), [coachesCollection.docs, selectedCoach.id]);

  const handleDisableIS = useCallback(async () => {
    if (selectedCoach.id && selectedCoachData) {
      setShowConfirmationModal(false);
      await selectedCoachData.updateFields({ isEnrolledInISProgram: false });
    }
  }, [selectedCoachData, selectedCoach.id]);

  return (
    <>
      <StyledHeader>
        <Title>{texts.title}</Title>
        <OptionContainer>
          <CoachSelect role={role} onChange={(coach) => setSelectedCoach(coach || {})} />
          {isInsideSalesAdmin() && isISConfigView && !!selectedCoachData?.isEnrolledInISProgram
            && (
              <StyledButton onClick={() => setShowConfirmationModal(true)}>
                {texts.disableIS}
              </StyledButton>
            )}

        </OptionContainer>
      </StyledHeader>
      <StyledTabs
        tabsConfig={tabsConfig}
        selectedTab={selectedTab}
        onSelectTab={setSelectedTab}
      />
      {tabContent}
      <ConfirmDialog
        isOpen={showConfirmationModal}
        onConfirm={handleDisableIS}
        onCancel={() => setShowConfirmationModal(false)}
        dialogTexts={{
          title: texts.modalTitle,
          content: texts.modalContent,
        }}
      />
    </>
  );
};

CoachConfigurations.propTypes = {
  defaultTab: PropTypes.string.isRequired,
  tabsConfig: PropTypes.object.isRequired,
  role: PropTypes.string,
  isISConfigView: PropTypes.bool,
};

CoachConfigurations.defaultProps = {
  role: '',
  isISConfigView: false,
};

export default CoachConfigurations;
