import styled from 'styled-components';

import FormikInput from '../../../../../../components/v2/FormikInput';

const FormContainer = styled.div`
  padding: 24px 50px 10px 50px;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 310px;
  margin-bottom: 20px;
`;

const StyledFormikInput = styled(FormikInput)`
  margin: 0;

  .MuiOutlinedInput-root {
    height: 45px;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  .MuiOutlinedInput-root {
    min-width: 150px;
  }
  .MuiInputBase-root {
    width: 100%;
    height: 40px;
  }
`;

const InputSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export {
  SubContainer,
  FormContainer,
  InputSelectContainer,
  StyledFormikInput,
  SelectContainer,
};
