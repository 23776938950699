import React from 'react';
import PropTypes from 'prop-types';

import User from '../../../../../Model/User';

import NotificationSettings from './NotificationSettings';
import NutritionSettings from './NutritionSettings';
import {
  Container,
} from './styles';

const ClientSettings = ({
  user,
  userDoc,
}) => (
  <Container>
    <NotificationSettings userDoc={userDoc} />
    <NutritionSettings clientId={user} />
  </Container>
);

ClientSettings.propTypes = {
  user: PropTypes.string.isRequired,
  userDoc: PropTypes.instanceOf(User),
};

ClientSettings.defaultProps = {
  userDoc: null,
};

export default ClientSettings;
