import styled, { css } from 'styled-components';
import { IconButton } from '@mui/material';
import { CheckCircleOutline, RadioButtonUnchecked } from '@mui/icons-material';

import colors from '../../../../../../../styles/colors';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.shades.secondary8};
  height: 30px;
  width: auto;
  align-items: center;
  padding: 0 5px;
  overflow: hidden;
`;

const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  flex-grow: 1;
`;

const StyledIconButton = styled(IconButton)`
  width: 5px;
  color: ${colors.base.alpha};
  margin-right: 5px;
`;

const radioButtonStyles = css`
  width: 18px;
  height: 18px;
  > path {
    stroke: ${colors.base.primary};
  }
`;

const StyledCheckCircleOutline = styled(CheckCircleOutline)`
  ${radioButtonStyles}
`;

const StyledRadioButtonUnchecked = styled(RadioButtonUnchecked)`
  ${radioButtonStyles}
`;

export {
  Container,
  Title,
  StyledIconButton,
  StyledCheckCircleOutline,
  StyledRadioButtonUnchecked,
};
