import React, {
  useState,
  useEffect,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import moment from 'moment';
import format from 'string-template';
import PropTypes from 'prop-types';

import {
  Card,
  HeaderContainer,
  TitleContainer,
  Title,
} from '../../../../../../components/v2/Card';
import InputLabel from '../../../../../../components/v2/InputLabel';
import { StyledTextFieldContainer } from '../../../../../../components/Inputs';
import User from '../../../../../../Model/User';
import useToast from '../../../../../hooks/useToast';
import RadioButtonGroup from '../../../../RadioButtonGroup';
import LoadingOverlay from '../../../../LoadingOverlay';
import { SecondaryText } from '../styles';

import NotificationDisableModal from './NotificationDisableModal';
import { NotificationType } from './utils';
import texts from './texts.json';

const NotificationSettings = ({ userDoc }) => {
  const {
    workoutNotificationsResumeDate,
    mealPlanNotificationsResumeDate,
  } = userDoc || {};

  const [workoutNotificationEnabled, setWorkoutNotificationEnabled] = useState(true);
  const [mealPlanNotificationEnabled, setMealPlanNotificationEnabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [disabledNotificationType, setDisabledNotificationType] = useState(null);
  const { showToast } = useToast();

  useEffect(() => {
    if (workoutNotificationsResumeDate) {
      setWorkoutNotificationEnabled(moment(workoutNotificationsResumeDate).isBefore(moment()));
    }
    if (mealPlanNotificationsResumeDate) {
      setMealPlanNotificationEnabled(moment(mealPlanNotificationsResumeDate).isBefore(moment()));
    }
  }, [
    mealPlanNotificationsResumeDate,
    workoutNotificationsResumeDate,
  ]);

  const handleSave = async (notificationType, resumeDate) => {
    setIsLoading(true);
    try {
      if (notificationType === NotificationType.WORKOUT) {
        await userDoc.updateWorkoutNotificationsResumeDate(resumeDate);
        setWorkoutNotificationEnabled(moment(resumeDate).isBefore(moment()));
      } else if (notificationType === NotificationType.MEAL_PLAN) {
        await userDoc.updateMealPlanNotificationsResumeDate(resumeDate);
        setMealPlanNotificationEnabled(moment(resumeDate).isBefore(moment()));
      }
      showToast(texts.notificationUpdateSuccessMessage);
    } catch (error) {
      showToast(format(texts.notificationUpdateFailureMessage, { error }), { error: true });
    } finally {
      setIsLoading(false);
      setDisabledNotificationType(null);
    }
  };

  /*
   * If user is going to disable the notification then show modal to configure the disable type.
   * Otherwise directly call the save function
   */
  const onChangeState = (notificationType, shouldEnableNotification) => {
    if (!shouldEnableNotification) {
      setDisabledNotificationType(notificationType);
    } else {
      handleSave(notificationType, moment().toDate());
    }
  };

  return (
    <Card>
      <HeaderContainer>
        <TitleContainer>
          <Title>{texts.title}</Title>
        </TitleContainer>
      </HeaderContainer>
      {!isLoading && (
        <>
          <StyledTextFieldContainer $withMargin>
            <InputLabel>{texts.workoutNotificationTitle}</InputLabel>
            <SecondaryText $withMargin>{texts.workoutNotificationDescription}</SecondaryText>
            <RadioButtonGroup
              options={[
                {
                  label: texts.enable,
                  value: texts.enable,
                }, {
                  label: texts.disable,
                  value: texts.disable,
                },
              ]}
              selectedOption={workoutNotificationEnabled ? texts.enable : texts.disable}
              onOptionChange={(value) => onChangeState(NotificationType.WORKOUT, value === texts.enable)}
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer>
            <InputLabel>{texts.mealPlanNotificationTitle}</InputLabel>
            <SecondaryText $withMargin>{texts.mealPlanNotificationDescription}</SecondaryText>
            <RadioButtonGroup
              options={[
                {
                  label: texts.enable,
                  value: texts.enable,
                }, {
                  label: texts.disable,
                  value: texts.disable,
                },
              ]}
              selectedOption={mealPlanNotificationEnabled ? texts.enable : texts.disable}
              onOptionChange={(value) => onChangeState(NotificationType.MEAL_PLAN, value === texts.enable)}
            />
          </StyledTextFieldContainer>
        </>
      )}
      {!!disabledNotificationType && (
        <NotificationDisableModal
          disabledNotificationType={disabledNotificationType}
          onClose={() => setDisabledNotificationType(null)}
          handleSave={handleSave}
        />
      )}
      <LoadingOverlay isLoading={isLoading} />
    </Card>
  );
};

NotificationSettings.propTypes = {
  userDoc: PropTypes.instanceOf(User),
};

NotificationSettings.defaultProps = {
  userDoc: null,
};

export default compose(
  observer,
)(NotificationSettings);
