import React, {
  useCallback,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react';
import format from 'string-template';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import moment from 'moment-timezone';

import useComponentMounted from '../../../../hooks/useComponentMounted';
import useLogger from '../../../../hooks/useLogger';
import { CoachingActivity } from '../../../../utils/log';
import { isCRXAdmin } from '../../../../utils/postHog';
import LoggedInUserContext from '../../../../context/LoggedInUserContext';
import CoachesListContext from '../../../context/CoachesListContext';
import useToast from '../../../hooks/useToast';
import LoadingOverlay from '../../../components/LoadingOverlay';
import AutoComplete from '../../../components/AutoComplete';
import InputLabel from '../../../../components/v2/InputLabel';
import {
  CardsContainer,
  Card,
  Container,
} from '../../../../components/v2/Card';
import { SaveButton } from '../../../../components/Button/ActionButtons';
import {
  StyledTextField,
  StyledTextFieldContainer,
} from '../../../../components/Inputs';

import texts from './texts.json';

const MAX_CHARACTERS_COUNT = 100;
const timeZoneOptions = moment.tz.names().map((tz) => ({ id: tz, label: tz }));

const CoachSettings = ({
  coach: {
    id: coachId,
    label: coach,
  },
}) => {
  const { coachesCollection } = useContext(CoachesListContext);
  const { userDoc } = useContext(LoggedInUserContext);

  const [coachName, setCoachName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [coachTimezone, setCoachTimezone] = useState('');

  const { showToast } = useToast();
  const { logCoachingActivity } = useLogger();
  const isComponentMountedRef = useComponentMounted();

  const isReadOnly = !isCRXAdmin();

  useEffect(() => {
    const coachDoc = coachesCollection.docs.find((doc) => doc.id === coachId);
    setCoachName(coachDoc.name);
    setCoachTimezone(coachDoc.timeZone || '');
  }, [
    coachId,
    coachesCollection.docs,
  ]);

  const isError = useMemo(() => (
    !coachName || (coachName.length > MAX_CHARACTERS_COUNT)
  ), [coachName]);

  const handleSave = useCallback(async () => {
    // Don't save if there is an error with the input name
    if (isError) {
      return;
    }

    // Or if there is another coach with the same display name
    const matchingDoc = coachName !== coach && coachesCollection.docs.find(({ name }) => name === coachName);
    if (matchingDoc) {
      showToast(texts.duplicateName, { error: true });
      return;
    }
    const coachDoc = coachesCollection.docs.find((doc) => doc.id === coachId);

    if (!!coachDoc.timeZone && !coachTimezone) {
      showToast(texts.timezoneChangeWarning, { error: true });
      return;
    }

    setIsLoading(true);
    await coachDoc.updateFields({
      name: coachName,
      ...(coachTimezone && { timeZone: coachTimezone }),
      lastUpdatedByUserName: userDoc.name,
    });
    if (isComponentMountedRef.current) {
      setIsLoading(false);
    }
    logCoachingActivity(CoachingActivity.UPDATED_COACH_SETTINGS);
    showToast(texts.saved);
  }, [
    isComponentMountedRef,
    logCoachingActivity,
    showToast,
    coachesCollection.docs,
    userDoc,
    coachName,
    coachId,
    coach,
    isError,
    coachTimezone,
  ]);

  return (
    <CardsContainer $fullWidth>
      <Card>
        <Container>
          <StyledTextFieldContainer $withMargin>
            <InputLabel>{texts.coachName}</InputLabel>
            <StyledTextField
              value={coachName}
              disabled={isReadOnly}
              onChange={(event) => setCoachName(event.target.value)}
              error={isError}
              helperText={coachName.length
                ? format(texts.characterCount, {
                  count: coachName.length,
                  maxCount: MAX_CHARACTERS_COUNT,
                })
                : texts.required}
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer $withMargin>
            <InputLabel>{texts.coachTimezone}</InputLabel>
            <AutoComplete
              value={coachTimezone}
              options={timeZoneOptions}
              onChange={(data) => setCoachTimezone(data?.id || '')}
              isOptionEqualToValue={(option, value) => option.id === value || value === ''}
            />
          </StyledTextFieldContainer>
          <SaveButton
            onClick={handleSave}
            disabled={isLoading}
            $fitToContent
          >
            {texts.saveButton}
          </SaveButton>
        </Container>
        <LoadingOverlay isLoading={isLoading} />
      </Card>
    </CardsContainer>
  );
};

CoachSettings.propTypes = {
  coach: PropTypes.object.isRequired,
};

export default compose(
  observer,
)(CoachSettings);
