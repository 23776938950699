import BillingDetails from './components/BillingDetails';
import CheckInDetailsSlider from './components/CheckInDetailsSlider';
import FoodLog from './components/FoodLog';
import ClientInfo from './components/ClientInfo';
import Notes from './components/Notes';
import WorkoutCalendar from './components/WorkoutCalendar';
import WorkoutHistory from './components/WorkoutHistory';
import NutritionalHistory from './components/NutritionalHistory';
import ClientSettings from './components/ClientSettings';
import ClientHistory from './components/ClientHistory';

import {
  StyledBillingIcon,
  StyledCheckinIcon,
  StyledForkIcon,
  StyledUserIcon,
  StyledNotesIcon,
  StyledSettings,
  StyledMealPlanIcon,
  StyledWorkoutHistoryIcon,
  StyledExerciseIcon,
  StyledHistoryIcon,
} from './styles';
import texts from './texts.json';

const clientInfoTabs = {
  CHECKIN_ACTIVITY: 'CHECKIN_ACTIVITY',
  BILLING_DETAILS: 'BILLING_DETAILS',
  FOOD_LOG: 'FOOD_LOG',
  CLIENT_INFO: 'CLIENT_INFO',
  NOTES: 'NOTES',
  WORKOUT_CALENDAR: 'WORKOUT_CALENDAR',
  WORKOUT_HISTORY: 'WORKOUT_HISTORY',
  NUTRITIONAL_HISTORY: 'NUTRITIONAL_HISTORY',
  CLIENT_SETTINGS: 'CLIENT_SETTINGS',
  CLIENT_HISTORY: 'CLIENT_HISTORY',
};

const clientInfoTabsConfig = {
  [clientInfoTabs.CHECKIN_ACTIVITY]: {
    label: texts.tabLabels[clientInfoTabs.CHECKIN_ACTIVITY],
    icon: StyledCheckinIcon,
    component: CheckInDetailsSlider,
  },
  [clientInfoTabs.BILLING_DETAILS]: {
    label: texts.tabLabels[clientInfoTabs.BILLING_DETAILS],
    icon: StyledBillingIcon,
    component: BillingDetails,
  },
  [clientInfoTabs.FOOD_LOG]: {
    label: texts.tabLabels[clientInfoTabs.FOOD_LOG],
    icon: StyledForkIcon,
    component: FoodLog,
  },
  [clientInfoTabs.CLIENT_INFO]: {
    label: texts.tabLabels[clientInfoTabs.CLIENT_INFO],
    icon: StyledUserIcon,
    component: ClientInfo,
  },
};

const quickInfoTabsConfig = {
  [clientInfoTabs.NOTES]: {
    label: texts.tabLabels[clientInfoTabs.NOTES],
    icon: StyledNotesIcon,
    component: Notes,
  },
  [clientInfoTabs.WORKOUT_CALENDAR]: {
    label: texts.tabLabels[clientInfoTabs.WORKOUT_CALENDAR],
    icon: StyledExerciseIcon,
    component: WorkoutCalendar,
  },
  [clientInfoTabs.WORKOUT_HISTORY]: {
    label: texts.tabLabels[clientInfoTabs.WORKOUT_HISTORY],
    icon: StyledWorkoutHistoryIcon,
    component: WorkoutHistory,
  },
  [clientInfoTabs.NUTRITIONAL_HISTORY]: {
    label: texts.tabLabels[clientInfoTabs.NUTRITIONAL_HISTORY],
    icon: StyledMealPlanIcon,
    component: NutritionalHistory,
  },
  [clientInfoTabs.CLIENT_SETTINGS]: {
    label: texts.tabLabels[clientInfoTabs.CLIENT_SETTINGS],
    icon: StyledSettings,
    component: ClientSettings,
  },
  [clientInfoTabs.CLIENT_HISTORY]: {
    label: texts.tabLabels[clientInfoTabs.CLIENT_HISTORY],
    icon: StyledHistoryIcon,
    component: ClientHistory,
  },
};

export {
  clientInfoTabs,
  quickInfoTabsConfig,
  clientInfoTabsConfig,
};
