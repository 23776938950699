import React from 'react';

import { clientInfoTabs } from '../../CoachTools/components/ClientInfo/utils';

const initialValues = {
  isOpen: false,
  selectedUserId: null,
  openQAP: () => {},
  closeQAP: () => {},
  selectedTab: clientInfoTabs.NOTES,
};

const QAPContext = React.createContext(initialValues);

export default QAPContext;
export {
  initialValues,
};
