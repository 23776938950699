import { MeasurementUnit } from '../../../../../../../utils/measurement';

const texts = {
  heightValues: {
    [MeasurementUnit.METRIC]: '{height} cm',
    [MeasurementUnit.IMPERIAL]: '{ft}\'{in}"',
  },
  weightValues: {
    [MeasurementUnit.METRIC]: '{weight} kg',
    [MeasurementUnit.IMPERIAL]: '{weight} lb',
  },
  personalDetails: 'Personal Details',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  phone: 'Phone',
  location: 'Location',
  weight: 'Weight',
  height: 'Height',
  gender: 'Gender',
  age: 'Age',
  birthdate: 'Birthday',
  checkInDay: 'Check-in day',
  edit: 'Edit',
  support: {
    userDoc: 'User Doc',
    stripeCustomer: 'Stripe Customer',
  },
  noStripeCustomer: 'There is no stripe customer account for this user',
};

export default texts;
