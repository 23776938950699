import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import useUserDoc from '../../hooks/useUserDoc';
import useToolsNavigation from '../../hooks/useToolsNavigation';
import LoadingPage from '../../../components/LoadingPage';
import BackButton from '../BackButton';
import ManageClientContext from '../../context/ManageClientContext';
import config from '../../../config';
import { StyledTabs } from '../../pages/MealsPlanner/styles';

import UserInfoSection from './components/UserInfoSection';
import MobileViewModal from './components/MobileView';
import {
  clientInfoTabsConfig,
  quickInfoTabsConfig,
} from './utils';
import {
  TabHeaderRow,
  HeaderContainer,
  TabButtonsContainer,
  ClientContainer,
  ClientSideContainer,
  ClientRightContainer,
} from './styles';
import texts from './texts.json';

const ClientInfoView = ({
  clientIdForQAP,
  isQAP,
  selectedQAPTab,
}) => {
  const { navigateBack } = useToolsNavigation();
  const {
    selectedTab: clientViewTab,
    setSelectedTab: setclientViewSelectedTab,
  } = useContext(ManageClientContext);

  const {
    params: {
      clientId: clientIdForClientView,
      userId: coachId,
    },
  } = useRouteMatch();

  const [showMobileViewModal, setShowMobileViewModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(selectedQAPTab || clientViewTab);
  const clientId = clientIdForQAP || clientIdForClientView;

  const {
    isReady: isClientDocReady,
    userDoc: clientDoc,
  } = useUserDoc(clientId);

  const mobileUrl = useMemo(() => `${config.mobileAppURL}/u/${clientId}/home?coachId=${coachId}`,
    [clientId, coachId]);

  const tabContent = useMemo(() => {
    const { component: TabContent, props } = isQAP
      ? quickInfoTabsConfig[selectedTab]
      : clientInfoTabsConfig[selectedTab];

    return (
      <TabContent
        user={clientId}
        userDoc={clientDoc}
        {...props}
      />
    );
  }, [
    selectedTab,
    isQAP,
    clientDoc,
    clientId,
  ]);

  const onTabChange = useCallback((value) => {
    setSelectedTab(value);

    if (!isQAP) {
      setclientViewSelectedTab(value);
    }
  }, [
    isQAP,
    setclientViewSelectedTab,
  ]);

  if (!isClientDocReady) {
    return <LoadingPage />;
  }

  return (
    <>
      <ClientContainer>
        <ClientSideContainer>
          <UserInfoSection
            clientId={clientId}
            userDoc={clientDoc}
            showMobileViewModal={setShowMobileViewModal}
            isQAP={isQAP}
          />
        </ClientSideContainer>
        <ClientRightContainer>
          <HeaderContainer>
            <TabHeaderRow>
              <StyledTabs
                tabsConfig={isQAP ? quickInfoTabsConfig : clientInfoTabsConfig}
                selectedTab={selectedTab}
                onSelectTab={onTabChange}
              />
              {!isQAP && (
                <TabButtonsContainer>
                  <BackButton
                    label={texts.goBack}
                    onClick={navigateBack}
                  />
                </TabButtonsContainer>
              )}
            </TabHeaderRow>
          </HeaderContainer>
          {tabContent}
        </ClientRightContainer>
      </ClientContainer>

      <MobileViewModal
        showModal={showMobileViewModal}
        onClose={() => setShowMobileViewModal(false)}
        mobileUrl={mobileUrl}
      />
    </>
  );
};

ClientInfoView.propTypes = {
  isQAP: PropTypes.bool,
  clientIdForQAP: PropTypes.string,
  selectedQAPTab: PropTypes.string,
};

ClientInfoView.defaultProps = {
  isQAP: false,
  selectedQAPTab: '',
  clientIdForQAP: '',
};

export default compose(
  observer,
)(ClientInfoView);
