import format from 'string-template';
import moment from 'moment';

import { UserActivityType } from '../../../../Model/UserActivity';
import { DateFormat } from '../../../../../utils/date';
import { TimeDurationFormat } from '../../../../../utils/time';
import {
  formatAmoutInCents,
  getCurrencySymbol,
} from '../../../../../utils/formatters';

import texts from './texts';

/**
 * Generates the content and subContent strings based on the activity's type and data.
 *
 * @param {object} activity - The activity object
 * @returns {object} The content and subContent of the activity
 */
const getActivityContent = (activity) => {
  let content = '';
  let subContent = '';

  const data = activity.activityData;
  switch (activity.type) {
    case UserActivityType.WORKOUT_COMPLETED:
      content = format(texts.workoutComplete, {
        workoutName: data.workoutName,
        assignDate: moment(data.assignedFor.toDate()).format(DateFormat.DATE_FORMAT_COMMA),
      });
      break;

    case UserActivityType.CHECK_IN_SUBMITTED:
      content = format(texts.checkInSubmit, {
        weekText: data.weekText,
        year: data.year,
      });
      break;

    case UserActivityType.PAYMENT_SUCCESSFUL:
    case UserActivityType.PAYMENT_FAILED:
    case UserActivityType.PAYMENT_REFUNDED: {
      const amount = formatAmoutInCents(data.amount);
      const currency = getCurrencySymbol(data.currency);
      if (activity.type === UserActivityType.PAYMENT_FAILED) {
        content = format(texts.failPayment, {
          currency,
          amount,
          invoice: data.invoice,
        });
        subContent = format(texts.paymentFailReason, { reason: data.failure_message });
      } else if (activity.type === UserActivityType.PAYMENT_REFUNDED) {
        content = format(texts.refundPayment, {
          currency,
          amount,
          invoice: data.invoice,
        });
      } else {
        content = format(texts.successPayment, {
          currency,
          amount,
          invoice: data.invoice,
        });
      }
      break;
    }

    case UserActivityType.HABIT_SUBMITTED: {
      const habitText = data.habitStat.map((habit) => `“${habit}”`).join(', ');
      content = format(texts.habitCheckedOff, {
        habits: habitText,
        weekText: data.weekText,
        year: data.year,
      });
      break;
    }
    case UserActivityType.LEAD_CALL_PLACED:
      content = format(texts.callPlaced, {
        userName: data.createdBy,
      });
      break;

    case UserActivityType.LEAD_MSG:
      content = format(texts.message, {
        msgType: data.isSentMsg ? texts.send : texts.received,
        message: data.message,
        time: moment(activity.createdAt).format(TimeDurationFormat.TWELVE_HOUR_FORMAT),
      });
      break;

    case UserActivityType.POST_PAYMENT_FORM_SUBMIT:
      content = format(texts.postPaymentForSubmit, {
        time: moment(activity.createdAt).format(TimeDurationFormat.TWELVE_HOUR_FORMAT),
      });
      break;

    case UserActivityType.SIGN_UP:
      content = format(texts.signUp, {
        time: moment(activity.createdAt).format(TimeDurationFormat.TWELVE_HOUR_FORMAT),
      });
      break;

    case UserActivityType.LEAD_STATUS_CHANGE:
      content = format(texts.statusChange, {
        type: texts.lead,
        status: data.leadStatus,
      });
      break;

    case UserActivityType.SUBSCRIPTION_STATUS_CHANGE:
      content = format(texts.statusChange, {
        type: texts.subscription,
        status: data.subscriptionStatus,
      });
      break;

    default:
      break;
  }
  return {
    content,
    subContent,
  };
};

export {
  getActivityContent,
};
