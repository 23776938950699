import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const FilterWrapper = styled.div`
  padding: 20px 20px 0 20px;
  display: flex;
  justify-content: flex-end;
`;

export {
  Container,
  FilterWrapper,
};
