import React, {
  useState,
  useContext,
  useRef,
  useEffect,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import Draggable from 'react-draggable';

import QAPContext from '../../../context/QAPContext';
import ClientInfoView from '../ClientInfo';

import {
  CloseButton,
  StyledCloseIcon,
  Handle,
  StyledPopper,
  StyledResizableBox,
} from './styles';
import {
  getDeviceType,
  dynamicSizes,
} from './utils';

const deviceType = getDeviceType();

const sizes = {
  MIN_WIDTH: dynamicSizes[deviceType].MIN_WIDTH,
  MIN_HEIGHT: dynamicSizes[deviceType].MIN_HEIGHT,
  MAX_WIDTH: dynamicSizes[deviceType].MAX_WIDTH,
  MAX_HEIGHT: dynamicSizes[deviceType].MAX_HEIGHT,
};

const DELAY_TIME = 1000;

const ClientQuickInfoModal = () => {
  const {
    closeQAP,
    selectedUserId,
    selectedTab,
    isOpen,
  } = useContext(QAPContext);

  const [width, setWidth] = useState(sizes.MAX_WIDTH);
  const [height, setHeight] = useState(sizes.MAX_HEIGHT);
  const resizableRef = useRef(null);
  const tabContentRef = useRef(null);
  const draggableRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (tabContentRef.current) {
        const totalHeight = tabContentRef.current.scrollHeight + tabContentRef.current.offsetTop;
        setHeight(totalHeight > sizes.MAX_HEIGHT ? sizes.MAX_HEIGHT : totalHeight);
      }
    // We wait a brief period to see if the tab content is rendered properly, before calculating the height
    }, DELAY_TIME);
    return () => clearTimeout(timer);
  }, [selectedTab]);

  const onResize = (event, { size }) => {
    setWidth(size.width);
    setHeight(size.height);
  };

  return (
    <Draggable nodeRef={draggableRef} handle="#quickAccessPanel">
      <StyledPopper
        sx={{ zIndex: 1300 }}
        open={isOpen}
        onClose={closeQAP}
        ref={draggableRef}
      >
        <CloseButton
          onClick={closeQAP}
          $fullScreen
        >
          <StyledCloseIcon />
        </CloseButton>
        <StyledResizableBox
          width={width}
          height={height}
          onResize={onResize}
          minConstraints={[sizes.MIN_WIDTH, sizes.MIN_HEIGHT]}
          maxConstraints={[sizes.MAX_WIDTH, sizes.MAX_HEIGHT]}
          handle={<Handle />}
          ref={resizableRef}
        >
          <ClientInfoView isQAP clientIdForQAP={selectedUserId} selectedQAPTab={selectedTab} />
        </StyledResizableBox>
      </StyledPopper>
    </Draggable>
  );
};

export default compose(
  observer,
)(ClientQuickInfoModal);
