import React, {
  useMemo,
  useState,
  useContext,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import useToolsNavigation from '../../../../hooks/useToolsNavigation';
import MealPlanContext, { withMealPlanContextReady } from '../../../../context/MealPlanContext';
import ChatButton from '../../../ChatButton';
import ClientQuickInfoButton from '../../../ClientQuickInfoButton';
import ProfileDetailsSection from './components/ProfileDetailsSection';
import ClientTagsSection from './components/ClientTagsSection';
import SubscriptionDetailsSection from './components/SubscriptionDetailsSection';
import { accordionId } from './utils';
import {
  Container,
  Name,
  StyledAvatar,
  ButtonContainer,
  StyledButton,
  StyledMealPlanIcon,
  StyledMobileIcon,
} from './styles';
import texts from './texts.json';

const EMPTY_ACCORDION = '';

const UserInfoSection = ({
  clientId,
  userDoc,
  showMobileViewModal,
  isQAP,
}) => {
  const {
    name,
    avatarUrl,
    customTags,
  } = userDoc;

  const [expanded, setExpanded] = useState(accordionId.PROFILE_DETAILS_ACCORDION);

  const { navigateToClientMealPlanPage } = useToolsNavigation();
  const { mealPlanAssignments } = useContext(MealPlanContext);

  const isMealPlanAvailable = useMemo(() => (
    !!mealPlanAssignments?.find((assignment) => assignment.id === clientId)
  ), [
    clientId,
    mealPlanAssignments,
  ]);

  // Allow only one accordion to be open at a time
  const handleExpandSection = useCallback((accordion) => (event, isExpanded) => {
    setExpanded(isExpanded ? accordion : EMPTY_ACCORDION);
  }, []);

  return (
    <Container id="quickAccessPanel" isQAP={isQAP}>
      <StyledAvatar src={avatarUrl} />
      <Name>{name}</Name>
      <ButtonContainer>
        <ChatButton
          userId={userDoc.id}
          text={texts.chatLabel}
          popperPlacement="right-start"
          textIconButton
        />
        {!isQAP && <ClientQuickInfoButton userId={userDoc.id} textIconButton />}
        {isMealPlanAvailable && (
          <StyledButton
            onClick={() => navigateToClientMealPlanPage(clientId)}
          >
            <StyledMealPlanIcon />
            {texts.mealPlan}
          </StyledButton>
        )}
        <StyledButton
          onClick={() => showMobileViewModal(true)}
        >
          <StyledMobileIcon />
          {texts.AppView}
        </StyledButton>
      </ButtonContainer>
      <ProfileDetailsSection
        clientId={clientId}
        userDoc={userDoc}
        handleExpand={handleExpandSection}
        expanded={expanded}
        isQAP={isQAP}
      />
      <ClientTagsSection
        userId={clientId}
        customTags={customTags}
        handleExpand={handleExpandSection}
        expanded={expanded}
        isQAP={isQAP}
      />
      <SubscriptionDetailsSection
        userDoc={userDoc}
        handleExpand={handleExpandSection}
        expanded={expanded}
        isQAP={isQAP}
      />
    </Container>
  );
};

UserInfoSection.propTypes = {
  clientId: PropTypes.string.isRequired,
  userDoc: PropTypes.object.isRequired,
  showMobileViewModal: PropTypes.func.isRequired,
  isQAP: PropTypes.bool.isRequired,
};

export default compose(
  withMealPlanContextReady,
  observer,
)(UserInfoSection);
