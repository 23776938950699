import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';

import ManageClientTags from '../../../../../ManageClientTags';
import { InfoTag } from '../../../../../../../components/Tags';
import {
  InfoContainer,
  StyledSubTitle,
  StyledTitle,
  StyledAccordion,
  StyledArrowIcon,
} from '../../styles';
import { accordionId } from '../../utils';

import {
  TagsSection,
  ChipContainer,
} from './styles';
import texts from './texts.json';

const ClientTagsSection = ({
  userId,
  customTags,
  handleExpand,
  expanded,
  isQAP,
}) => (
  <>
    <Divider flexItem />
    <StyledAccordion
      key={texts.clientTags}
      title={<StyledTitle>{texts.clientTags}</StyledTitle>}
      expanded={expanded === accordionId.CLIENT_TAG_ACCORDION || !isQAP}
      onChange={handleExpand(accordionId.CLIENT_TAG_ACCORDION)}
      expandIcon={
        !isQAP ? (
          <ManageClientTags
            selectedClientList={[userId]}
            assignedTags={customTags}
            buttonLabel={texts.edit}
            editButton
          />
        ) : <StyledArrowIcon />
      }
      preventRotation={!isQAP}
      disableGutters
    >
      <InfoContainer>
        <TagsSection>
          {customTags.length !== 0 ? (
            <ChipContainer>
              {customTags.map((tag) => (
                <InfoTag key={tag}>{tag}</InfoTag>
              ))}
            </ChipContainer>
          ) : (
            <StyledSubTitle>
              {texts.noTags}
            </StyledSubTitle>
          )}
        </TagsSection>
      </InfoContainer>
    </StyledAccordion>
  </>
);

ClientTagsSection.propTypes = {
  userId: PropTypes.string.isRequired,
  customTags: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleExpand: PropTypes.func.isRequired,
  expanded: PropTypes.string.isRequired,
  isQAP: PropTypes.bool.isRequired,
};

export default ClientTagsSection;
