import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  Formik,
} from 'formik';

import Activity from '../../../../../../Model/Activity';
import Select from '../../../../../../components/Select';
import InputLabel from '../../../../../../components/v2/InputLabel';
import DialogRoundedModal from '../../../../../../components/DialogRoundedModal';
import {
  TimeUnit,
  getSecondsFrom,
} from '../../../../../../utils/time';
import { ReactComponent as PauseIcon } from '../../../../../../assets/icons/v2/pause.svg';
import { ReactComponent as CreateIcon } from '../../../../../../assets/icons/v2/creation-plus-circle.svg';
import { PrimaryButton } from '../../../../../../components/Button/ActionButtons';

import { EditorActionType } from '../../WorkoutEditor/utils';

import {
  initialValues as startValues,
  validationSchema,
} from './validation';
import FormField from './formFields';
import {
  SubContainer,
  FormContainer,
  InputSelectContainer,
  StyledFormikInput,
  SelectContainer,
} from './styles';
import texts from './texts';

const restTimeOptions = Object.values(TimeUnit).map((unit) => ({
  value: unit,
  label: texts.timeUnits[unit] || '',
}));

const RestModal = ({
  isOpen,
  onClose,
  onSave,
  activity,
}) => {
  const onAddUpdateRest = (values) => {
    const newActivity = new Activity({
      type: EditorActionType.REST,
      duration: getSecondsFrom(Number(values.rest), values.restTimeDisplayUnit),
      durationDisplayUnit: values.restTimeDisplayUnit,
    });
    onSave([newActivity]);
  };

  return (
    <DialogRoundedModal
      title={activity ? texts.title.edit : texts.title.add}
      IconComponent={<PauseIcon />}
      fullWidth
      open={isOpen}
      onClose={onClose}
    >
      <FormContainer>
        <Formik
          initialValues={startValues(activity)}
          validationSchema={validationSchema}
          onSubmit={onAddUpdateRest}
          enableReinitialize
        >
          {({ isSubmitting, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <SubContainer>
                <InputLabel>{`${texts.fieldLabel[FormField.REST_TIME]}:`}</InputLabel>
                <InputSelectContainer>
                  <StyledFormikInput
                    name={FormField.REST_TIME}
                    type="number"
                    width="150"
                  />
                  <SelectContainer>
                    <Field
                      name={FormField.REST_TIME_DISPLAY_UNIT}
                      component={({ field }) => (
                        <Select
                          {...field}
                          options={restTimeOptions}
                        />
                      )}
                    />
                  </SelectContainer>
                </InputSelectContainer>
              </SubContainer>
              <PrimaryButton
                icon={<CreateIcon />}
                type="submit"
                disabled={isSubmitting}
              >
                {activity ? texts.actions.update : texts.actions.add}
              </PrimaryButton>
            </form>
          )}
        </Formik>
      </FormContainer>
    </DialogRoundedModal>
  );
};

RestModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  activity: PropTypes.object,
};

RestModal.defaultProps = {
  onClose: () => {},
  onSave: () => {},
  activity: {},
};

export default RestModal;
