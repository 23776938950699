import React from 'react';

import CoachConfigurations from '../CoachConfigurations/CoachConfigurations';
import {
  tabs,
  tabsConfig,
} from './tabs';

const ISCoachConfigurations = () => (
  <CoachConfigurations
    defaultTab={tabs.LEADS}
    tabsConfig={tabsConfig}
    isISConfigView
  />
);

export default ISCoachConfigurations;
