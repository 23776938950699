import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import useComponentMounted from '../../../../../hooks/useComponentMounted';
import useSessionStore from '../../../../../hooks/useSessionStore';
import LoadingPage from '../../../../../components/LoadingPage';
import ContractHistory from '../../../../../components/ContractHistory';
import FirebaseContext from '../../../../../context/FirebaseContext';
import User from '../../../../../Model/User';
import UserContract from '../../../../../Model/UserContract';
import Subscription from '../../../../../Model/Subscription';
import CollectionName from '../../../../../utils/collections';
import { openFirestoreDocument } from '../../../../../utils/support';

import {
  Container,
  StyledContractDetails,
  StyledOnCallActions,
} from './styles';
import texts from './texts.json';
import BillingSummary from './BillingSummary';

const BillingDetails = ({ userDoc }) => {
  const [userContractDoc, setUserContractDoc] = useState(null);
  const [subscriptionDoc, setSubscriptionDoc] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const { isOnCallUser } = useSessionStore();
  const isComponentMountedRef = useComponentMounted();
  const { firebase: { remote } } = useContext(FirebaseContext);

  useEffect(() => {
    const init = async () => {
      const {
        id: userId,
      } = userDoc;
      const subscription = await Subscription.getCurrentSubscription(userId);
      const userContract = await UserContract.getCurrentContractByUserId(userId);

      if (isComponentMountedRef.current) {
        if (subscription) {
          setSubscriptionDoc(subscription);
        }
        if (userContract) {
          setUserContractDoc(userContract);
        } else {
          Sentry.captureException(new Error(texts.noContract), {
            extra: {
              userId,
            },
          });
        }
        setIsReady(true);
      }
    };
    if (!isReady) {
      init();
    }
  }, [
    userDoc,
    isReady,
    isComponentMountedRef,
    remote,
  ]);

  if (!isReady) {
    return <LoadingPage />;
  }

  return (
    <Container>
      <BillingSummary
        userDoc={userDoc}
        userContractDoc={userContractDoc}
        subscriptionDoc={subscriptionDoc}
      />
      {!!userContractDoc && (
        <StyledContractDetails>
          {isOnCallUser && (
            <StyledOnCallActions
              actions={[
                {
                  onClick: () => {
                    openFirestoreDocument(`${CollectionName.INTEGRATIONS}/stripe/subscriptions/${subscriptionDoc.id}`);
                  },
                  disabled: !subscriptionDoc?.id,
                  label: texts.support.subscriptionDoc,
                },
              ]}
            />
          )}
          <ContractHistory userId={userDoc.id} />
        </StyledContractDetails>
      )}
    </Container>
  );
};

BillingDetails.propTypes = {
  userDoc: PropTypes.instanceOf(User),
};

BillingDetails.defaultProps = {
  userDoc: null,
};

export default compose(
  observer,
)(BillingDetails);
