import styled, { css } from 'styled-components';
import { Button } from '@mui/material';

import { ReactComponent as PersonIcon } from '../../../assets/icons/people-icon.svg';
import { ReactComponent as BillingIcon } from '../../../assets/icons/billing.svg';
import { ReactComponent as CheckinIcon } from '../../../assets/icons/check-in.svg';
import { ReactComponent as ForkIcon } from '../../../assets/icons/fork.svg';
import { ReactComponent as AssignmentIcon } from '../../../assets/icons/v2/article-check.svg';
import { ReactComponent as Settings } from '../../../assets/icons/settings-gear-icon.svg';
import { ReactComponent as MealPlannerIcon } from '../../../assets/icons/menuIcons/meal-planner.svg';
import { ReactComponent as WorkoutLibraryIcon } from '../../../assets/icons/menuIcons/workout-library.svg';
import { ReactComponent as ExerciseEditorIcon } from '../../../assets/icons/menuIcons/exercise-editor.svg';
import { ReactComponent as History } from '../../../assets/icons/v2/archive.svg';
import { ReactComponent as OtherIcon } from '../../../assets/icons/folder-icon.svg';
import Tabs from '../Tabs';
import colors from '../../../styles/colors';

const ClientContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
`;

const ClientSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 440px;
  overflow-y: auto;
  background-color: ${colors.shades.secondary10};
  border-right: 1px solid ${colors.shades.secondary7};
`;

const ClientRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.shades.gamma5};
`;

const TabButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
`;

const TabHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const iconStyles = css`
  height: 20px;
  width: 20px;
  margin-right: 8px;
`;

const StyledTabs = styled(Tabs)`
  flex: 1;
  border-color: ${colors.base.primary};

  & .Mui-selected {
    background-color: ${colors.base.primary};
  }
`;

const StyledForkIcon = styled(ForkIcon)`
  ${iconStyles};
`;

const StyledOtherIcon = styled(OtherIcon)`
  ${iconStyles};
`;

const StyledSettings = styled(Settings)`
  ${iconStyles};
`;

const createStyledIcon = (IconComponent) => styled(IconComponent)`
  ${iconStyles};

  > path {
    stroke: ${colors.base.beta};
    fill: ${colors.shades.secondary7};
  }
  > line {
    stroke: ${colors.base.beta};
  }
`;

const StyledCheckinIcon = createStyledIcon(CheckinIcon);
const StyledBillingIcon = createStyledIcon(BillingIcon);
const StyledUserIcon = createStyledIcon(PersonIcon);
const StyledNotesIcon = createStyledIcon(AssignmentIcon);
const StyledMealPlanIcon = createStyledIcon(MealPlannerIcon);
const StyledWorkoutHistoryIcon = createStyledIcon(WorkoutLibraryIcon);
const StyledExerciseIcon = createStyledIcon(ExerciseEditorIcon);
const StyledHistoryIcon = createStyledIcon(History);

const StyledButton = styled(Button)`
  border-radius: 5px;
  margin-top: 10px;
  background: ${colors.base.primary};
  color: ${colors.base.beta};
  height: fit-content;
  padding: 10px 14px;
  &.MuiButton-text {
    font-size: 14px;
    line-height: 16px;
  }
  &:hover {
    background: ${colors.shades.primary1};
  }
`;

export {
  HeaderContainer,
  TabButtonsContainer,
  TabHeaderRow,
  StyledBillingIcon,
  StyledCheckinIcon,
  StyledForkIcon,
  StyledUserIcon,
  StyledNotesIcon,
  StyledSettings,
  StyledMealPlanIcon,
  StyledOtherIcon,
  StyledWorkoutHistoryIcon,
  StyledExerciseIcon,
  StyledHistoryIcon,
  StyledButton,
  iconStyles,
  StyledTabs,
  ClientContainer,
  ClientSideContainer,
  ClientRightContainer,
};
