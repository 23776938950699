import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Divider, MenuItem } from '@mui/material';

import { StyledTextField } from '../../../../../../../../../components/Inputs';
import InputLabel from '../../../../../../../../../components/v2/InputLabel';
import RadioButtonGroup from '../../../../../../../RadioButtonGroup';
import profileDetailsTexts from '../../../ProfileDetailsSection/texts';
import {
  MeasurementUnit,
  HeightUnit,
  WeightUnit,
  getFtInFromCentimeters,
  getCentimetersFromFtIn,
  getLbsFromKg,
  getKgFromLbs,
} from '../../../../../../../../../utils/measurement';
import { NutritionProperties, BiologicalSex } from '../../../../../../../../utils/macroGoals';
import {
  RowContainer,
  FieldContainer,
  StyledSelect,
  StyledInputLabel,
} from '../../styles';

import texts from './texts';

const ZERO_VALUE = 0;
const STEP_SIZE = 0.1;
const HEIGHT_MAX_INCHES = 11;

const renderGenderOptions = Object.values(BiologicalSex).map((gender) => ({
  label: texts.genderValues[gender],
  value: gender,
}));

const NutritionDetailsEditField = ({
  nutritionProfileFormData,
  setNutritionProfileFormData,
  errors,
  setErrors,
}) => {
  const renderOptions = useCallback((items) => (
    Object.entries(items).map(([key, value]) => (
      <MenuItem key={key} value={key}>{value}</MenuItem>
    ))
  ), []);

  const handleNutritionProfileInfoChange = useCallback((field, value) => {
    setNutritionProfileFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  }, [setNutritionProfileFormData]);

  // Change weight value as units change
  const updateWeight = useCallback(() => {
    const changeWeight = nutritionProfileFormData.weightMeasurementUnit === MeasurementUnit.METRIC
      ? getLbsFromKg(nutritionProfileFormData.currentWeight, true)
      : getKgFromLbs(nutritionProfileFormData.currentWeight, true);
    handleNutritionProfileInfoChange(NutritionProperties.CURRENT_WEIGHT, changeWeight);
  }, [
    nutritionProfileFormData,
    handleNutritionProfileInfoChange,
  ]);

  // Change height value as units change
  const updateHeight = useCallback(() => {
    if (nutritionProfileFormData.heightMeasurementUnit === MeasurementUnit.METRIC) {
      const ftInches = getFtInFromCentimeters(nutritionProfileFormData.height);
      handleNutritionProfileInfoChange(NutritionProperties.HEIGHT, { ft: ftInches.ft, in: ftInches.inches });
    } else {
      const cm = getCentimetersFromFtIn(
        { ft: nutritionProfileFormData.height.ft, inches: nutritionProfileFormData.height.in },
        true,
      );
      handleNutritionProfileInfoChange(NutritionProperties.HEIGHT, cm);
    }
  }, [
    nutritionProfileFormData,
    handleNutritionProfileInfoChange,
  ]);

  const onChangeHeightUnit = useCallback((e) => {
    handleNutritionProfileInfoChange(NutritionProperties.HEIGHT_MEASUREMENT_UNIT, e.target.value);
    updateHeight();
    setErrors({
      ...errors, cm: null, ft: null, inches: null,
    });
  }, [
    errors,
    setErrors,
    updateHeight,
    handleNutritionProfileInfoChange,
  ]);

  const onChangeWeightUnit = useCallback((e) => {
    handleNutritionProfileInfoChange(NutritionProperties.WEIGHT_MEASUREMENT_UNIT, e.target.value);
    updateWeight();
  }, [
    updateWeight,
    handleNutritionProfileInfoChange,
  ]);

  return (
    <>
      <FieldContainer>
        <InputLabel>{texts.unitOfHeight}</InputLabel>
        <StyledSelect
          value={nutritionProfileFormData.heightMeasurementUnit}
          onChange={(e) => onChangeHeightUnit(e)}
        >
          {renderOptions(HeightUnit)}
        </StyledSelect>
      </FieldContainer>
      <FieldContainer>
        <InputLabel>{profileDetailsTexts.height}</InputLabel>
        {nutritionProfileFormData.heightMeasurementUnit === MeasurementUnit.METRIC && (
          <StyledTextField
            placeholder={texts.cm}
            type={texts.number}
            value={nutritionProfileFormData.height.toFixed(1)}
            inputProps={{ min: ZERO_VALUE, step: STEP_SIZE }}
            onChange={(e) => handleNutritionProfileInfoChange(
              NutritionProperties.HEIGHT,
              Math.max(ZERO_VALUE, parseFloat(e.target.value) || ZERO_VALUE),
            )}
            error={!!errors.cm}
            helperText={errors.cm}
          />
        )}
        {nutritionProfileFormData.heightMeasurementUnit !== MeasurementUnit.METRIC && (
          <>
            <Divider />
            <RowContainer>
              <StyledInputLabel>{texts.ft}</StyledInputLabel>
              <StyledTextField
                placeholder={texts.ft}
                type={texts.number}
                value={nutritionProfileFormData.height.ft}
                inputProps={{ min: ZERO_VALUE }}
                onChange={(e) => handleNutritionProfileInfoChange(
                  NutritionProperties.HEIGHT,
                  {
                    ...nutritionProfileFormData.height,
                    ft: Math.max(ZERO_VALUE, parseInt(e.target.value, 10) || ZERO_VALUE),
                  },
                )}
                error={!!errors.ft}
                helperText={errors.ft}
              />
              <StyledInputLabel>{texts.inches}</StyledInputLabel>
              <StyledTextField
                placeholder={texts.inches}
                type={texts.number}
                value={nutritionProfileFormData.height.in}
                inputProps={{ min: ZERO_VALUE, max: HEIGHT_MAX_INCHES }}
                onChange={(e) => handleNutritionProfileInfoChange(
                  NutritionProperties.HEIGHT,
                  {
                    ...nutritionProfileFormData.height,
                    in: Math.max(ZERO_VALUE, Math.min(parseInt(e.target.value, 10) || ZERO_VALUE, HEIGHT_MAX_INCHES)),
                  },
                )}
                error={!!errors.inches}
                helperText={errors.inches}
              />
            </RowContainer>
          </>
        )}
      </FieldContainer>
      <FieldContainer>
        <InputLabel>{texts.unitOfWeight}</InputLabel>
        <StyledSelect
          value={(nutritionProfileFormData.weightMeasurementUnit)}
          onChange={(e) => onChangeWeightUnit(e)}
        >
          {renderOptions(WeightUnit)}
        </StyledSelect>
      </FieldContainer>
      <FieldContainer>
        <InputLabel>{profileDetailsTexts.weight}</InputLabel>
        <StyledTextField
          type={texts.number}
          value={nutritionProfileFormData.currentWeight.toFixed(1)}
          inputProps={{ min: ZERO_VALUE, step: STEP_SIZE }}
          onChange={(e) => handleNutritionProfileInfoChange(
            NutritionProperties.CURRENT_WEIGHT,
            Math.max(ZERO_VALUE, parseFloat(e.target.value) || ZERO_VALUE),
          )}
          error={!!errors.currentWeight}
          helperText={errors.currentWeight}
        />
      </FieldContainer>
      <FieldContainer>
        <InputLabel>{profileDetailsTexts.gender}</InputLabel>
        <RadioButtonGroup
          options={renderGenderOptions}
          showStyledOptions={false}
          selectedOption={nutritionProfileFormData.biologicalSex}
          onOptionChange={(value) => handleNutritionProfileInfoChange(
            NutritionProperties.BIOLOGICAL_SEX, value,
          )}
        />
      </FieldContainer>
    </>
  );
};

NutritionDetailsEditField.propTypes = {
  nutritionProfileFormData: PropTypes.object.isRequired,
  setNutritionProfileFormData: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,
};

export default NutritionDetailsEditField;
