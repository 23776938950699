import * as Yup from 'yup';

import { convertSeconds, TimeUnit } from '../../../../../../utils/time';
import fieldName from './formFields';
import texts from './texts';

const initialValues = (activity) => ({
  [fieldName.REST_TIME]: convertSeconds(activity?.duration, activity?.durationDisplayUnit) || '',
  [fieldName.REST_TIME_DISPLAY_UNIT]: activity?.durationDisplayUnit || TimeUnit.SECONDS,
});

const validationSchema = Yup.object().shape({
  [fieldName.REST_TIME]: Yup.number()
    .integer(texts.validation.integerNumber)
    .positive(texts.validation.positiveNumber)
    .required(texts.validation.requiredField),
});

export {
  initialValues,
  validationSchema,
};
