import React, {
  useState,
  useContext,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import moment from 'moment';
import { TextField } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';
import { forEachSeries } from 'p-iteration';

import ExternalCoachContext from '../../../../../../context/ExternalCoachContext';
import WorkoutAssignment from '../../../../../../../Model/WorkoutAssignment';
import Program from '../../../../../../../Model/Program';
import Workout from '../../../../../../../Model/Workout';
import User from '../../../../../../../Model/User';
import DialogRoundedModal from '../../../../../../../components/DialogRoundedModal';
import { SaveButton, CancelButton } from '../../../../../../../components/Button/ActionButtons';
import LoadingOverlay from '../../../../../LoadingOverlay';
import useComponentMounted from '../../../../../../../hooks/useComponentMounted';
import useLogger from '../../../../../../../hooks/useLogger';
import useToast from '../../../../../../hooks/useToast';
import { CoachingActivity } from '../../../../../../../utils/log';
import { DateFormat } from '../../../../../../../utils/date';
import AssignmentRepeatInput from '../AssignmentRepeatInput';

import {
  DialogBody,
  StyledAutocomplete,
  StyledOption,
  ColumnContainer,
  RowContainer,
  StyledHelperText,
  CheckBoxContainer,
  StyledCheckBox,
} from './styles';
import texts from './texts.json';

const DEFAULT_REPETITIONS = 1;

const ProgramAssignmentModal = ({
  showModal,
  onClose,
  userDoc,
  selectedDate,
}) => {
  const [programCollection, setProgramCollection] = useState({ docs: [] });
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [showLoading, setShowLoading] = useState(true);
  const [isKeepWindowOpen, setIsKeepWindowOpen] = useState(false);
  const [programRepetitions, setProgramRepetitions] = useState(DEFAULT_REPETITIONS);

  const { externalCoachDoc: { id: coachId } } = useContext(ExternalCoachContext);
  const isComponentMountedRef = useComponentMounted();
  const { showToast } = useToast();
  const { logCoachingActivity } = useLogger();

  useEffect(() => {
    const loadPrograms = async () => {
      const coachProgramCol = await Program.getProgramsByCoach(coachId);
      if (isComponentMountedRef.current) {
        setProgramCollection(coachProgramCol);
        setShowLoading(false);
      }
    };
    loadPrograms();
  }, [coachId, isComponentMountedRef]);

  const programOptions = useMemo(() => programCollection.docs
    .map((program) => ({
      id: program.id,
      label: program.name,
      program,
    })), [programCollection]);

  const endDate = useMemo(() => {
    const days = selectedProgram?.program.workouts.length * programRepetitions;
    return moment(selectedDate).add(days, 'day').format(DateFormat.SHORT_MONTH_DAY_COMMA_YEAR_FORMAT);
  }, [
    selectedDate,
    programRepetitions,
    selectedProgram,
  ]);

  const onAssignProgram = useCallback(async () => {
    setShowLoading(true);

    const workoutDocsCache = {};
    await forEachSeries(selectedProgram.program.workouts, async ({ workoutRef }, index) => {
      if (workoutRef) {
        if (!workoutDocsCache[workoutRef]) {
          const workout = await Workout.getWorkoutById(workoutRef);
          workoutDocsCache[workoutRef] = workout;
        }
        // Iterate for the amount of repetitions and add the workout in respective days
        let iteration = 0;
        const assignments = [];
        while (iteration < programRepetitions) {
          const day = selectedProgram.program.workouts.length * iteration + index;
          const promise = WorkoutAssignment.assignWorkout(userDoc, workoutDocsCache[workoutRef], {
            startDate: moment.utc(selectedDate).startOf('day').add(day, 'day'),
            endDate: moment.utc(selectedDate).endOf('day').add(day, 'day'),
            coachId,
            programId: selectedProgram.program.id,
          });
          assignments.push(promise);
          iteration += 1;
        }
        await Promise.all(assignments);
      }
    });
    logCoachingActivity(CoachingActivity.ASSIGNED_PROGRAM, {
      programId: selectedProgram.program.id,
      clientId: userDoc.id,
    });

    if (isComponentMountedRef.current) {
      showToast(texts.programAssigned);
      setShowLoading(false);
      setSelectedProgram(null);
      if (!isKeepWindowOpen) {
        onClose();
      }
    }
  }, [
    coachId,
    isComponentMountedRef,
    userDoc,
    isKeepWindowOpen,
    onClose,
    programRepetitions,
    selectedDate,
    selectedProgram,
    showToast,
    logCoachingActivity,
  ]);

  return (
    <DialogRoundedModal
      title={texts.title}
      description={selectedDate}
      fullWidth
      maxWidth="sm"
      actionButtons={(
        <ColumnContainer>
          <CheckBoxContainer>
            <StyledCheckBox
              checked={isKeepWindowOpen}
              onChange={() => setIsKeepWindowOpen((prev) => !prev)}
              size="small"
            />
            <StyledHelperText>{texts.keepWindowOpen}</StyledHelperText>
          </CheckBoxContainer>
          <RowContainer>
            <SaveButton
              disabled={!selectedProgram || !(programRepetitions > 0)}
              icon={<CheckCircleOutline />}
              onClick={onAssignProgram}
            >
              {texts.assignProgram}
            </SaveButton>
            <CancelButton onClick={onClose}>{texts.cancel}</CancelButton>
          </RowContainer>
        </ColumnContainer>
      )}
      open={showModal}
      onClose={onClose}
    >
      <DialogBody>
        <StyledAutocomplete
          disablePortal
          value={selectedProgram}
          options={programOptions}
          renderInput={(params) => <TextField {...params} label={texts.selectProgram} />}
          onChange={(event, value) => setSelectedProgram(value)}
          renderOption={(props, {
            id,
            label,
          }) => (
            <StyledOption {...props} key={id}>
              {label}
            </StyledOption>
          )}
        />
        <AssignmentRepeatInput
          helperText={texts.repeatHelperText}
          value={programRepetitions}
          onChange={setProgramRepetitions}
          startDate={selectedDate}
          endDate={endDate}
        />
      </DialogBody>
      <LoadingOverlay isLoading={showLoading} />
    </DialogRoundedModal>
  );
};

ProgramAssignmentModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userDoc: PropTypes.instanceOf(User),
  selectedDate: PropTypes.string.isRequired,
};

ProgramAssignmentModal.defaultProps = {
  userDoc: null,
};

export default compose(
  observer,
)(ProgramAssignmentModal);
