import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import format from 'string-template';
import moment from 'moment';
import { Divider } from '@mui/material';
import PropTypes from 'prop-types';

import CollectionName from '../../../../../../../utils/collections';
import { MeasurementUnit } from '../../../../../../../utils/measurement';
import { openFirestoreDocument } from '../../../../../../../utils/support';
import { openStripeCustomer } from '../../../../../../utils/stripe';
import { DateFormat } from '../../../../../../../utils/date';
import { getCheckInDay } from '../../../../../../utils/checkIn';
import useSessionStore from '../../../../../../../hooks/useSessionStore';
import useToast from '../../../../../../hooks/useToast';
import StripeCustomerDocument from '../../../../../../../Model/StripeCustomerDocument';
import UserNutritionProfile from '../../../../../../Model/UserNutritionProfile';
import ExternalCoachContext from '../../../../../../context/ExternalCoachContext';
import PrimaryButton from '../../../../../../../components/Button/ActionButtons/PrimaryButton';
import InfoItem from '../../../InfoItem';
import ProfileEditModal from '../ProfileEditModal';
import {
  InfoContainer,
  InfoWrapper,
  StyledOnCallActions,
  StyledTitle,
  StyledAccordion,
  StyledArrowIcon,
} from '../../styles';
import { accordionId } from '../../utils';

import {
  StyledEditIcon,
} from './styles';
import texts from './texts';

const EMPTY_CELL = '-';
const genderLabel = {
  MALE: 'Male',
  FEMALE: 'Female',
};

const ProfileDetailsSection = ({
  clientId,
  userDoc,
  handleExpand,
  expanded,
  isQAP,
}) => {
  const {
    firstName,
    lastName,
    email,
    address,
    phoneNumber,
    birthdate,
    checkInDay,
  } = userDoc;

  const {
    externalCoachDoc: {
      stripeAccountId,
    },
  } = useContext(ExternalCoachContext);
  const { isOnCallUser } = useSessionStore();
  const { showToast } = useToast();

  const [userNutritionProfileDoc, setUserNutritionProfileDoc] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    const init = async () => {
      const nutritionProfile = new UserNutritionProfile(userDoc.id);
      await nutritionProfile.init();
      setUserNutritionProfileDoc(nutritionProfile);
    };

    init();
  }, [
    userDoc.id,
    handleExpand,
  ]);

  const {
    height,
    heightMeasurementUnit,
    currentWeight,
    weightMeasurementUnit,
  } = userNutritionProfileDoc || {};

  const heightText = useMemo(() => {
    if (!height || !heightMeasurementUnit) {
      return EMPTY_CELL;
    }

    return heightMeasurementUnit === MeasurementUnit.METRIC
      ? format(texts.heightValues[heightMeasurementUnit], { height })
      : format(texts.heightValues[heightMeasurementUnit],
        { ft: height.ft, in: height.in });
  }, [
    height,
    heightMeasurementUnit,
  ]);

  const currentWeightText = useMemo(() => {
    if (!currentWeight || !weightMeasurementUnit) {
      return EMPTY_CELL;
    }

    return format(texts.weightValues[weightMeasurementUnit], { weight: currentWeight });
  }, [
    currentWeight,
    weightMeasurementUnit,
  ]);

  const handleStripeCustomerClick = useCallback(async () => {
    const userStripeDoc = userDoc
      ? await StripeCustomerDocument.getStripeCustomerByUserId(userDoc.id)
      : null;
    if (!!stripeAccountId && !!userStripeDoc) {
      openStripeCustomer(stripeAccountId, userStripeDoc.id);
    } else {
      showToast(texts.noStripeCustomer, { type: 'error' });
    }
  }, [
    stripeAccountId,
    userDoc,
    showToast,
  ]);

  return (
    <>
      <Divider flexItem />
      <StyledAccordion
        key={texts.personalDetails}
        title={<StyledTitle>{texts.personalDetails}</StyledTitle>}
        expanded={(expanded === accordionId.PROFILE_DETAILS_ACCORDION) || !isQAP}
        onChange={handleExpand(accordionId.PROFILE_DETAILS_ACCORDION)}
        expandIcon={
          !isQAP ? (
            <PrimaryButton
              onClick={() => setShowEditModal(true)}
              variant="info"
              size="medium"
            >
              <StyledEditIcon />
              {texts.edit}
            </PrimaryButton>
          ) : <StyledArrowIcon />
        }
        preventRotation={!isQAP}
        disableGutters
      >
        <InfoContainer>
          <InfoWrapper>
            <InfoItem label={texts.firstName}>
              {firstName || EMPTY_CELL}
            </InfoItem>
            <InfoItem label={texts.lastName}>
              {lastName || EMPTY_CELL}
            </InfoItem>
            <InfoItem label={texts.email}>
              {email || EMPTY_CELL}
            </InfoItem>
            <InfoItem label={texts.phone}>
              {phoneNumber || EMPTY_CELL}
            </InfoItem>
            <InfoItem label={texts.location}>
              {address || EMPTY_CELL}
            </InfoItem>
            <InfoItem label={texts.gender}>
              {(userNutritionProfileDoc && genderLabel[userNutritionProfileDoc?.biologicalSex]) || EMPTY_CELL}
            </InfoItem>
            <InfoItem label={texts.age}>
              {birthdate ? moment().diff(moment(birthdate), 'years') : EMPTY_CELL}
            </InfoItem>
            <InfoItem label={texts.birthdate}>
              {birthdate ? moment(birthdate).utc().format(DateFormat.DEFAULT_DATE_FORMAT) : EMPTY_CELL}
            </InfoItem>
            <InfoItem label={texts.height}>
              {heightText}
            </InfoItem>
            <InfoItem label={texts.weight}>
              {currentWeightText}
            </InfoItem>
            <InfoItem label={texts.checkInDay}>
              {getCheckInDay(checkInDay)}
            </InfoItem>
          </InfoWrapper>
          {isOnCallUser && (
            <StyledOnCallActions
              actions={[
                {
                  onClick: () => openFirestoreDocument(`${CollectionName.USER}/${clientId}`),
                  disabled: !clientId,
                  label: texts.support.userDoc,
                },
                {
                  onClick: handleStripeCustomerClick,
                  disabled: !stripeAccountId,
                  label: texts.support.stripeCustomer,
                },
              ]}
            />
          )}
        </InfoContainer>
      </StyledAccordion>

      {showEditModal && (
        <ProfileEditModal
          userDoc={userDoc}
          userNutritionProfileDoc={userNutritionProfileDoc}
          showModal={showEditModal}
          onClose={() => setShowEditModal(false)}
        />
      )}
    </>
  );
};

ProfileDetailsSection.propTypes = {
  clientId: PropTypes.string.isRequired,
  userDoc: PropTypes.object.isRequired,
  handleExpand: PropTypes.func.isRequired,
  expanded: PropTypes.string.isRequired,
  isQAP: PropTypes.bool.isRequired,
};

export default compose(
  observer,
)(ProfileDetailsSection);
