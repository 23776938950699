import styled from 'styled-components';

const Container = styled.div`
  overflow: scroll;
  padding: 20px;
  width: 100%;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  max-width: 50%;
  min-width: 350px;
`;

export {
  Container,
  InfoContainer,
};
